import React from 'react';
import { Form } from 'react-bootstrap';
import { controlClass } from '../../../utility/ui-util';
import ControlLabel from "../ControlLabel/ControlLabel";
import StateContext from '../../../contexts/state-context';
import ReactMarkdown from 'react-markdown/with-html';
import ReactSelect from 'react-select';

const LOV = (props) => {
    const options = props.variable.values.map((e, key) => { return { "value": e.value, "label": e.label }; });
    return (
        <Form.Group>
            <ControlLabel isOptional={props.variable.isOptional} isObsolete={!!props.variable.isObsolete} text={props.variable.label} />
            <StateContext.Consumer>
                {({ setPropertyValue }) =>
                    <ReactSelect
                        isClearable={false}
                        classNames={{
                            control: (state) => controlClass(props.variable, props.variable.currentValue)
                        }}
                        onChange={(item) => { setPropertyValue(props.group, props.advanced, props.variableName, item.value, props.hostIndex) }}
                        disabled={!!props.disabled}
                        options={options}
                        value={{ value: props.variable.currentValue, label: options.find(o => o.value === props.variable.currentValue) ? options.find(o => o.value === props.variable.currentValue).label : props.variable.currentValue }} />
                }
            </StateContext.Consumer>
            <Form.Text className="text-muted">
                <ReactMarkdown
                    source={props.variable.description}
                    escapeHtml={false} />
            </Form.Text>
        </Form.Group>
    )
};

export default LOV;
