import React, { useState, useContext } from 'react';
import StateContext from '../../../contexts/state-context';

if (!String.format) {
  String.format = function (format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
        ;
    });
  };
}

const PreviewImage = (props) => {
  const [imgSrc, setImgSrc] = useState(null);
  let canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d'),
    tempImg = document.createElement('img');
  canvas.width = props.width;
  canvas.height = props.height;
  tempImg.addEventListener('load', onTempImageLoad);
  tempImg.src = 'data:image/svg+xml,' + encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" width="' + props.width + '" height="' + props.height + '"><foreignObject width="100%" height="100%">' + props.template + '</foreignObject></svg>');
  ctx.drawImage(tempImg, 0, 0);
  const targetImg = document.createElement('img');
  function onTempImageLoad(e) {
    ctx.drawImage(e.target, 0, 0);
    let imgDataUrl = canvas.toDataURL();
    setImgSrc(imgDataUrl);
  };


  return (
    <StateContext.Consumer>
      {({ setPropertyValue }) =>
        <div className='image-preview-container'>
          {(!!imgSrc) ?
            <img className={(props.showPreview) ? "branding-preview image-preview thumbnail" : "branding-preview image-preview"} src={imgSrc} 
            onLoad={(props.variableName)? e => {
              let imgDataUrl = e.target.src;
              let base64String = imgDataUrl.replace('data:', '').replace(/^.+,/, '');
              setPropertyValue(props.group, props.advanced, props.variableName, base64String);
          }:null}/> : null
          }
          <small className="thumbnail-title text-muted form-text">{props.title}</small>
        </div>
      }
    </StateContext.Consumer>
  )
};

export default PreviewImage;
