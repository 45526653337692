import React from 'react';
import { Form } from "react-bootstrap";

const ControlLabel = (props) => {
    return (
        <Form.Label className={'control-label' + (!!props.isObsolete ? ' text-muted' : '')}>{props.text}{props.isOptional === false && <span className='mandatory-label'>*</span>}{props.isReadOnly === true && <span className='readonly-label fa fa-lock' title='This value cannot be changed from the default.'/>}</Form.Label>
    )
};

export default ControlLabel;
