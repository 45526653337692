import React from 'react';
import { Card } from 'react-bootstrap';
import HostsEditorListItem from './HostsEditorListItem/HostsEditorListItem';
import StateContext from '../../../../contexts/state-context';

const { Header, Body } = Card;

const hostsList = (props) => {
    const items = [];

    items.push(Object.entries(props.hosts).sort(([k1, v1],[k2, v2]) => v1.index - v2.index).map(([k, v], index) => (
        <Card key={'host_' + index} className='host'>
            <Body>
            <HostsEditorListItem
                key={'host_' + index}
                index={index}
                hostname={k}
                host={v}
            />
        </Body>
        </Card>
    )));

    return (
        <Card className='hosts-area'>
            <StateContext.Consumer>
                {({ addHost }) => <Header as="h6">Hosts <button type='button' title='Add a new host' className='array-add-button text-success' onClick={() => addHost()}><i
                    className="fas fa-plus-circle fa-lg" /></button></Header>}
            </StateContext.Consumer>
            <Body>
                {items}
            </Body>
        </Card>
    )
};

export default hostsList;
