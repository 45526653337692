import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const header = (props) => (
    <header>
        <div>
            <nav className="navbar navbar-expand-sm navbar-light bg-light fixed-top py-3" style={{ marginTop: '1px', marginBottom: '10px', padding: '15px' }}>
                <div className="d-flex flex-grow-1">
                    <div className="navbar-brand">MDS Global CMP Installation Configuration</div>
                    <span className="w-100 d-lg-none d-block"></span>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbar-controls">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbar-controls">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item mx-1">
                                <Button variant="outline-info" onClick={props.onNewClick}>New</Button>
                            </li>
                            <li className="nav-item mx-1">
                                <Button variant="outline-info" onClick={props.onLoadClick}>Load</Button>
                            </li>
                            <li className="nav-item mx-1">
                                <Button variant="outline-info" disabled={props.saveButtonDisabled} onClick={props.onSaveClick}>Save</Button>
                            </li>
                        </ul>
                    </div>
                </div>
                {props.children}
            </nav>
        </div>
    </header>
);

export default header;
