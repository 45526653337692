import React from 'react';

const stateContext = React.createContext({
    state: undefined,
    addGroupHost: () => {},
    setGroupHostValue: () => {},
    deleteGroupHost: () => {},
    addArrayElement: () => {},
    deleteArrayElement: () => {},
    setArrayElementValue: () => {},
    setPropertyValue: () => {},
    getPropertyDefaultReference: () => {}
});

export default stateContext;
