import React, { useState } from 'react';
import Feedback from '../Feedback/Feedback';
import '../../../styles/VaultPassword.css';
import ControlLabel from "../ControlLabel/ControlLabel";

export default (props) => {
    const [buttonClass, setButtonClass] = useState('fa fa-eye');
    const [type, setType] = useState('password');
    return (
        <div className='form-group'>
            <ControlLabel isOptional={true} text='Vault Password'/>
            <div className='formWithButton'>
                <input
                    placeholder='Vault Password'
                    type={type}
                    disabled={!!props.disabled}
                    className={'form-control ' + props.className + ' custom-input'}
                    value={props.value}
                    onChange={props.onChange}
                />
                <button className='unmask'
                        title='Mask/Unmask password to check content' onClick={e => {
                    if (type === 'password') {
                        setButtonClass('fa fa-eye-slash');
                        setType('text');
                    } else {
                        setButtonClass('fa fa-eye');
                        setType('password');
                    }
                }}><i
                    className={buttonClass} aria-hidden='true'></i></button>
                <Feedback text={props.feedback} additionalClasses='invalid-feedback'/>
            </div>
        </div>
    )
};

