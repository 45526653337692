import React from 'react';
import { Form } from 'react-bootstrap';
import ControlLabel from "../ControlLabel/ControlLabel";
import ReactSelect from 'react-select';

const Select = (props) => {
    const options = props.values.map((e, key) => { return { "value": e.value, "label": e.label }; });
    return (
        <Form.Group>
            <ControlLabel isOptional={true} text={props.label} />
            <ReactSelect
                isClearable={false}
                onChange={props.onChange}
                disabled={!!props.disabled}
                options={options}
                value={{ value: props.currentValue, label: options.find(o => o.value === props.currentValue) ? options.find(o => o.value === props.currentValue).label : props.currentValue }} />
            {props.children}
        </Form.Group>
    )
};

export default Select;
