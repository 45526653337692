import React from 'react';
import PropertiesListItem from '../PropertiesListItem';
import { Card } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown/with-html';

const { Header, Body } = Card;

const containerProperty = (props) => {
    const items = [];

    items.push(Object.keys(props.property.children).map((name, index) => {
        return <PropertiesListItem
            key={index}
            name={props.name + '.' + name}
            group={props.group}
            property={props.property.children[name]}
            advanced={props.advanced}
        />;
    }));

    return (
        <Card>
            <Header>
              <Card.Title as="h6">{props.property.label}</Card.Title>
              {props.property.description && <Card.Subtitle as="small" className="text-muted">
                <ReactMarkdown
                  source={props.property.description}
                  escapeHtml={false}/></Card.Subtitle>}
            </Header>
            <Body>
                {items}
            </Body>
        </Card>
    )
};

export default containerProperty;
