import React from 'react';
import PropertiesListItem from '../PropertiesListItem/PropertiesListItem';

const advancedPropertiesList = (props) => {
    const items = [];
    const listHasProperties = props.properties !== undefined && props.properties.constructor === Object && Object.entries(props.properties).length > 0;

    if (listHasProperties) {
        items.push(Object.keys(props.properties)
            .filter((name) =>  props.properties[name].isHidden === undefined || !props.properties[name].isHidden)
            .map((name, index) => (
            <PropertiesListItem
                key={props.group + '_advanced_' + index}
                group={props.group}
                name={name}
                advanced={true}
                property={props.properties[name]}
                onValueChange={props.onValueChange}
            />
        )));
    }

    const propertiesList = listHasProperties ? (
        <div className="card">
            <div className="card-header" id="advanced-properties-heading">
                <h5 className="mb-0">
                    <button className="btn btn-link" data-toggle="collapse"
                            data-target="#advanced-properties"
                            aria-expanded="true" aria-controls="advanced-properties">
                        Advanced Properties
                    </button>
                </h5>
            </div>

            <div id="advanced-properties" className="collapse"
                 aria-labelledby="advanced-properties-heading">
                <div className="card-body">
                    {items}
                </div>
            </div>
        </div>
    ): null;

    return propertiesList;
};

export default advancedPropertiesList;
