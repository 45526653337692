import React, { Component } from 'react';
import { Modal, ProgressBar, Form } from 'react-bootstrap';
import VaultPassword from '../../components/UI/VaultPassword/VaultPassword';
import Button from '../../components/UI/Button/Button';
import Feedback from '../../components/UI/Feedback/Feedback';
import ControlLabel from '../../components/UI/ControlLabel/ControlLabel';
import FilesPrepareWorker from '../../workers/save.worker';
import fileDownload from "js-file-download";
import {
    encryptStateIfNecessary,
    getInventory,
    reconcileStateProperties,
    validateState
} from '../../processor/state-processor';
import { dump } from 'js-yaml';
import inventorySchema from '../../model/vaultType';

const { Title, Body, Footer } = Modal;

const initialState = {
    passwordControl: {
        class: 'validate',
        feedback: '',
        value: ''
    },
    inventoryFileName: '',
    saving: false,
    progress: 0,
    feedback: '',
    error: ''
};

class DownloadInventoryDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { ...initialState };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.password !== this.props.password) {
            this.setState({
                passwordControl: {
                    ...this.state.passwordControl,
                    value: this.props.password
                }
            })
        }

        if (prevProps.inventoryFileName !== this.props.inventoryFileName) {
            this.setState({ inventoryFileName: this.props.inventoryFileName });
        }
    }

    close = () => {
        this.setState(initialState);
        if (!!this.props.onClose) {
            this.props.onClose();
        }
    };

    handlePasswordControlInput = (event) => {
        this.setState({
            passwordControl: {
                class: 'validate',
                feedback: '',
                value: event.target.value
            },
        });
    };

    handleFileDownload = () => {
        this.setState({ saving: true });
        this.onFilesDownload(this.state.inventoryFileName, this.state.passwordControl.value, (e) => {
            if (e.status === 'error') {
                console.log(e.message);
                this.setState({ saving: false, error: String(e.message), progress: 0 });
            } else if (e.status === 'success') {
                this.setState({ saving: false, showDownloadInventoryDialog: false, progress: 0 });
                this.props.onDownload(this.state.inventoryFileName, this.state.passwordControl.value, e.state);
            } else {
                if (!!e.progress) {
                    this.setState({ progress: e.progress });
                }
                if (e.message !== undefined && e.message.trim() !== '') {
                    this.setState({ feedback: e.message });
                }
            }
        });
    };

    onFilesDownload = (inventoryFileName, password, statusCallback) => {
        const ua = window.navigator.userAgent;
        const isIE = /MSIE|Trident/.test(ua);
        let validate = validateState(this.props.state);
        if (validate.isValid) {
            if (typeof (Worker) !== "undefined" && !isIE) {
                let worker = new FilesPrepareWorker();
                worker.postMessage({ state: this.props.state, password: this.state.passwordControl.value });
                worker.onmessage = e => {
                    switch (e.data.status) {
                        case 'processing':
                            if (!!statusCallback) {
                                statusCallback(e.data);
                            }
                            break;
                        case 'success':
                            fileDownload(e.data.yaml, inventoryFileName + '.yaml');
                            fileDownload(JSON.stringify(e.data.encryptedState, null, 2), inventoryFileName + '.state.json');
                            worker.terminate();
                            worker = undefined;
                            if (!!statusCallback) {
                                statusCallback(e.data);
                            }
                            break;
                        case 'error':
                            if (!!statusCallback) {
                                statusCallback(e.data);
                            }
                            break;
                        default:
                            break;
                    }
                }
            } else {
                try {

                    const yaml = dump(getInventory(this.props.state, this.state.passwordControl.value), { schema: inventorySchema, lineWidth: -1 });
                    const reconciledState = reconcileStateProperties(this.props.state);
                    let reconciledStateCopy = JSON.parse(JSON.stringify(reconciledState));
                    delete reconciledStateCopy.hosts;
                    const encryptedState = encryptStateIfNecessary(reconciledStateCopy, this.state.passwordControl.value);
                    fileDownload(dump(yaml, { schema: inventorySchema, lineWidth: -1 }), 'inventory.yaml');
                    fileDownload(JSON.stringify(encryptedState, null, 2), 'state.json');
                    if (!!statusCallback) {
                        statusCallback({ status: 'success', yaml, state: reconciledState, encryptedState });
                    }

                } catch (e) {
                    if (!!statusCallback) {
                        statusCallback({ status: 'error', message: e });
                    }
                }
            }
        } else {
            if (!!statusCallback) {
                statusCallback({ status: 'error', message: validate.error });
            }
        }
    };

    render = () => (
        <div>
            <Modal show={this.props.show} backdrop={this.state.saving ? 'static' : true} onHide={this.close}>
                <Modal.Header closeButton={!this.state.saving}>
                    <Title>Download Inventory</Title>
                </Modal.Header>
                <Body>
                    <Form.Group>
                        <ControlLabel isOptional={false} text='File Name' />
                        <Form.Control type='text' className='form-control' placeholder='Inventory File Name' value={this.state.inventoryFileName} onChange={e => this.setState({ inventoryFileName: e.target.value })} />
                        <Form.Text className='form-text text-muted small'>The name of the inventory .yaml file. The corresponding state file will be stored with the name &lt;filename&gt;.state.json</Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <VaultPassword className={this.state.passwordControl.class} disabled={this.state.saving} value={this.state.passwordControl.value} onChange={this.handlePasswordControlInput} />
                    </Form.Group>
                    <Form.Group>
                        <Feedback text='Enter the Vault Password if you would like to encrypt sensitive properties. Leave blank to save inventory unencrypted.' additionalClasses='text-muted small' />
                    </Form.Group>
                    <Form.Group>
                        <ProgressBar hidden={!this.state.saving} animated variant='info' now={this.state.progress} label={`${this.state.progress}%`} />
                        <div className='form-text small' hidden={!this.state.saving || this.state.feedback === ''}>{this.state.feedback}</div>
                    </Form.Group>
                    <Form.Group>
                        <div className='form-text text-danger small' hidden={!!!this.state.error || this.state.error === ''}>{this.state.error}</div>
                    </Form.Group>
                </Body>
                <Footer>
                    <Button disabled={this.state.saving || this.state.inventoryFileName === undefined || this.state.inventoryFileName.trim() === ''} onClick={this.handleFileDownload} isProcessing={this.state.saving} processingText=' Saving...' idleText='Save' />
                </Footer>
            </Modal>
        </div>
    );
}

export default DownloadInventoryDialog;
