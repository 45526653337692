import React from 'react';
import { Form } from 'react-bootstrap';

const hostsListItem = (props) => (
            <div key={props.index} >
            <Form.Control 
                        className='host-input'
                        type='text'
                        value={props.hostname || ''}
                        readOnly={true}
                        plaintext={true}
                    />
                
            </div>
);

export default hostsListItem;
