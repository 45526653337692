import React, { Component } from 'react';
import { Form, Modal, Spinner } from "react-bootstrap";
import Button from "../../components/UI/Button/Button";
import Select from "../../components/UI/Select/Select";

const { Title, Body, Footer } = Modal;
const { Text } = Form;
const initialState = {
    inventoryVersion: {
        class: 'validate',
        feedback: '',
        value: 'model.json',
        extras: null
    },
    loading: false
};

class NewInventoryDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState};
        this.getModels();
    };

    getModels(){
        let self = this;
            fetch('models/inventory-versions.json',{
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                 }
              }
              ).then(function(response){
                console.log(response)
                return response.json();
              })
              .then(function(myJson) {
                self.inventoryVersions = myJson;
                self.setState({
                    inventoryVersion: {
                        class: 'validate',
                        feedback: '',
                        value: self.inventoryVersions[0].value,
                        extras: self.inventoryVersions[0].extras
                    }
                });
              });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show && this.props.show) {
            this.getModels();
        }
    }

    onNewClick = async (inventoryVersion) => {
        this.setState({ loading: true });
        this.props.onNewClick(inventoryVersion);
        this.setState({ loading: false });
    };

    handleInventoryVersionInput = (newValue) => {
      const inventoryVersion = this.inventoryVersions.filter(v => v.value === newValue.value)[0];
      this.setState({
          inventoryVersion: {
              class: 'validate',
              feedback: '',
              value: inventoryVersion.value,
              extras: inventoryVersion.extras
          }
      });
    };



    close = () => {
        this.setState(initialState);
        this.props.onClose();
    };

    render = () => (
        <Modal show={this.props.show} backdrop={this.state.loading ? 'static' : true} onHide={this.close}>
            <Modal.Header closeButton={!this.state.loading}>
                <Title>New Inventory</Title>
            </Modal.Header>
            <Body>
                <Select label="Inventory Version" currentValue={this.state.inventoryVersion.value} className={this.state.inventoryVersion.class} disabled={this.state.loading} values={this.inventoryVersions} onChange={this.handleInventoryVersionInput} />
            </Body>
            <Footer>
                <Button onClick={e => this.onNewClick(this.state.inventoryVersion) } isProcessing={this.state.loading} processingText=" Generating..." idleText="Create" />
            </Footer>
        </Modal>
    );
}

export default NewInventoryDialog;
