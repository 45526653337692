import React from 'react';
import { Col } from 'react-bootstrap';

const propertiesEditorPanel = (props) => {

    return (
        <Col sm={9}>
            {props.children}
        </Col>
    );
};

export default propertiesEditorPanel;
