import React from 'react';
import { Button, Spinner } from "react-bootstrap";

export default (props) => (
    <Button variant="primary" disabled={props.disabled} onClick={props.onClick}><Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        hidden={!props.isProcessing}
    />{props.isProcessing ? props.processingText : props.idleText}</Button>
)

