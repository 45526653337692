import React, { useState } from 'react';
import '../../../styles/Font.css';
import { Form } from 'react-bootstrap';
import { controlClass } from '../../../utility/ui-util';
import ControlLabel from "../ControlLabel/ControlLabel";
import StateContext from '../../../contexts/state-context';
import ReactMarkdown from 'react-markdown/with-html';

const FontFamily = (props) => {
    const className = props.variableName.substring(props.variableName.lastIndexOf('.') + 1).replace(/_/g, '-');
    const cssVarName = '--' + className;
    const root = document.documentElement;
    const useDefault = (props.variable.useDefault === undefined) ? true : false;
    const displayValue = (props.variable.currentValue === '') ? props.variable.currentDefaultValue : props.variable.currentValue;

    return (
        <Form.Group>
            <ControlLabel isOptional={props.variable.isOptional} isObsolete={!!props.variable.isObsolete} isReadOnly={props.variable.isReadOnly} text={props.variable.label}></ControlLabel>
            <div className='formWithButton'>
                <StateContext.Consumer>
                    {({ setPropertyValue }) => <Form.Control placeholder={props.variable.label}
                        className={controlClass(props.variable, (props.variable.isReadOnly && props.variable.forceDefault) ? props.variable.currentDefaultValue : props.variable.currentValue)}
                        type='text'
                        value={displayValue}
                        onChange={e => {
                            let value = e.target.value;
                            if (!useDefault && value === props.variable.currentDefaultValue) {
                                value = '';
                            }
                            let fontValue = value;
                            if (value === '') {
                                fontValue = props.variable.currentDefaultValue;
                            }
                            setPropertyValue(props.group, props.advanced, props.variableName, value, props.hostIndex);
                            root.style.setProperty(cssVarName, fontValue);
                        }}
                        readOnly={props.variable.isReadOnly}
                        plaintext={props.variable.isReadOnly}
                    />}
                </StateContext.Consumer>
            </div>
            <div className={"theme-font-preview " + className}>ABCDEFGabcdefg123456</div>
            <Form.Text className="text-muted">
                <ReactMarkdown
                    source={props.variable.description}
                    escapeHtml={false} />
            </Form.Text>
        </Form.Group>
    )
};

export default FontFamily;
