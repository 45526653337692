import React from 'react';

const home = (props) => (
    <div style={{marginTop: '120px'}}>
        <div className="card home mx-auto">
            <div className="card-body">
                Before using this Installation Configuration tool and before starting an installation of CMP, please consult the CMP documentation available at <a href="https://cmp-docs.com">cmp-docs.com</a> in general and the CMP Installation Guide in particular
            </div>
        </div>
    </div>
);

export default home;