import React, { useContext, useState } from 'react';
import StateContext from '../../../../../contexts/state-context';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { Col, Nav, Row } from 'react-bootstrap';
import PropertiesList from '../../../PropertiesEditorPanel/PropertiesList/PropertiesList';
import ReactMarkdown from 'react-markdown/with-html';

const { Label } = Form;

const hostsEditorListItem = (props) => {
    const groups = [];

    Object.entries(useContext(StateContext).state.groups).map(
        ([key, value], index) => {
            return {
                "value": key,
                "label": value.label
            };
        }).forEach((group) => groups.push(group));


    const hostProperties = Object.assign({}, ...Object.entries(useContext(StateContext).state.groups).map(
        ([key, value], index) => {
            return {
                [key]: (value.properties.hostProperties) ? value.properties.hostProperties : {}
            };
        }));

    const hostGroups = [];

    hostGroups.push(props.host.groups.map((hostGroup, index) => (

        <StateContext.Consumer key={'form_group_' + index}>
            {({ setHostGroupValue, deleteHostGroup, addHostGroup }) =>
                <Row key={'form_group_' + index} className='software'>
                    <Col sm={6}>
                        {index === 0 && (
                            <Label className={'control-label'}>Software <button type='button' title='Add a new software application to the host' className='array-add-button text-success' onClick={() => addHostGroup(props.group, props.index)}><i
                                className="fas fa-plus-circle fa-lg" /></button></Label>
                        )}
                        <div key={'form_group_' + index} className='formWithButton'>
                            <Select
                                key={'group_' + index}
                                isClearable={false}
                                classNames={{
                                    control: (state) => 'form-control custom-select first'
                                }}
                                onChange={(newValue) => { setHostGroupValue(props.index, index, newValue.value) }}
                                required
                                options={groups.filter(g => g.value === hostGroup.name || props.host.groups.findIndex((h) => g.value === h.name) < 0)}
                                value={hostGroup.name === undefined ? null : groups.find((g) => g.value === hostGroup.name)} />
                            <button className='unmask first'
                                title={`Remove '${hostGroup.name !== undefined ? hostGroup.name : ''}' from host`}
                                onClick={e => {
                                    deleteHostGroup(props.index, index)
                                }}
                            >
                                <i className='far far fa-trash-alt text-danger' aria-hidden='true'></i>
                            </button>
                        </div>
                        {hostGroup.name === undefined &&
                            <Form.Text className="text-muted">
                                <ReactMarkdown
                                    source={'Select software to be installed on the host from the available list. Please note that some restrictions may apply. Consult the documentation for further information.'}
                                    escapeHtml={false} />
                            </Form.Text>
                        }
                    </Col>
                    {hostGroup.name !== undefined && Object.keys(hostProperties[hostGroup.name]).length > 0 && (
                        <Col>
                            <PropertiesList
                                group={hostGroup.name}
                                properties={props.host.groups[index].properties}
                                hostIndex={props.index}
                            />
                        </Col>
                    )}
                </Row>
            }
        </StateContext.Consumer>

    )));
    const [hostName, setHostName] = useState('');
    const [errors, setErrors] = useState({});

    const handleHostNameChange = (index, value, validateHostValue, setHostValue, hostName) => {
        if(hostName === value.trim()){
            return;
        }
        setHostName(value.trim());
        const allErrors = validateHostValue(index, value);
        if (Object.keys(allErrors).length > 0) {
            setErrors(allErrors);
            return;
        }
        setHostName('');
        setErrors({});
        setHostValue(index, value);

    };
    return (
        <Row>
            <Col sm={4}>
                <Label className={'control-label'}>Host Name <span className='mandatory-label'>*</span></Label>
                <StateContext.Consumer>
                    {({ validateHostValue, setHostValue, deleteHost }) =>
                        <div key={props.index} className='formWithButton'>
                            <input
                                placeholder='Host Name'
                                type='text'
                                className={'form-control custom-input'}
                                value={hostName || props.hostname}
                                onChange={e => { handleHostNameChange(props.index, e.target.value, validateHostValue, setHostValue, hostName || props.hostname) }}
                            />
                            <button className='unmask'
                                title={`Delete '${props.hostname !== undefined ? props.hostname : 'empty'}' host`}
                                onClick={e => {
                                    deleteHost(props.index)
                                }}
                            >
                                <i className='far far fa-trash-alt text-danger' aria-hidden='true'></i>
                            </button>
                        </div>
                    }
                </StateContext.Consumer>
                {errors.invalid && (
                    <div class="form-text text-danger small">
                        {errors.invalid}
                    </div>
                )}
                <Form.Text className="text-muted">
                    <ReactMarkdown
                        source={'Fully Qualified Domain Name of the Host'}
                        escapeHtml={false} />
                </Form.Text>
            </Col>
            {Object.keys(errors).length === 0 && (
            <Col>
                {hostGroups.length === 0  && (
                    <StateContext.Consumer>
                        {({ addHostGroup }) =>
                            <Label className={'control-label'}>Software <button type='button' title='Add a new software application to the host' className='array-add-button text-success' onClick={() => addHostGroup(props.group, props.index)}><i
                                className="fas fa-plus-circle fa-lg" /></button></Label>
                        }
                    </StateContext.Consumer>
                )}
                {hostGroups}
            </Col>
)}
        </Row>
    );
};

export default hostsEditorListItem;
