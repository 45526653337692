import React, { useContext, useState } from 'react';
import reactCSS from 'reactcss';
import StateContext from '../../../contexts/state-context';

const ColorPreview = (props) => {
    const propertyName = props.group + (props.advanced ? '.advancedProperties.' : '.') + props.variableName,
        displayValue = useContext(StateContext).getPropertyDefaultReference(propertyName).currentValue;

    const handleLoad = async (target, setPropertyValue) => {
        if (props.variableName) {
            const styles = getComputedStyle(target);
            const rgb = styles.getPropertyValue("background-color");
            let hex = '';
            if (rgb.includes("srgb")) {
                hex = srgb2Hex(rgb);
                if (hex.toUpperCase() !== displayValue.toUpperCase()) {
                    setPropertyValue(props.group, props.advanced, props.variableName, hex);
                }
            } else {
                hex = rgb2Hex(rgb);
                if (hex.toUpperCase() !== displayValue.toUpperCase()) {
                    setPropertyValue(props.group, props.advanced, props.variableName, hex);
                }
            }
        }
    };

    const rgb2Hex = (s) => {
        return s.match(/[0-9]+/g).reduce((a, b) => a + componentToHex(parseInt(b)), '#');
    }

    const srgb2Hex = (s) => {
        return s.match(/\d+\.\d+/g).reduce((a, b) => a + componentToHex(sRGBto255RGB(b)), '#');
    }

    const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    const sRGBto255RGB = (s) => {
        let f = parseFloat(s);
        let f2 = Math.max(0.0, Math.min(1.0, f));
        let rgbVal = Math.floor(f2 == 1.0 ? 255 : f2 * 256.0);
        return rgbVal;
    }

    const styles = reactCSS({
        'default': {
            color: {
                width: '100%',
                height: '25px',
                borderRadius: '3px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'lightgray',
                background: props.color
            },
            swatch: {
                padding: '6px',
                background: '#ffffff',
                borderRadius: '2px',
                display: 'inline-block',
                boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
                width: '100px',
                margin: 'auto',
                display: 'block',
                backgroundColor: 'Transparent',
                backgroundRepeat: 'no-repeat',
                borderRadius: '3px',
                outline: 'none',
                textAlign: 'center',
                fontWeight: 'bold',
            },
        },
    });

    return (
        <StateContext.Consumer>
            {({ setPropertyValue }) =>
                <div className='image-preview-container'>
                    <div style={styles.swatch}>
                        <img style={styles.color} src={'transparent-pixel.png?_=' + encodeURIComponent(props.color)}
                            onLoad={e => {
                                handleLoad(e.target, setPropertyValue);
                            }} />
                        <small className="thumbnail-title text-muted form-text">{displayValue}</small>
                    </div>
                    <small className="thumbnail-title text-muted form-text">{props.title}</small>
                </div>
            }
        </StateContext.Consumer>
    )
};

export default ColorPreview;
