import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import DownloadInventoryDialog from '../DownloadInventoryDialog/DownloadInventoryDialog';
import UploadInventoryDialog from '../UploadInventoryDialog/UploadInventoryDialog';
import NewInventoryDialog from '../NewInventoryDialog/NewInventoryDialog';
import Panel from '../../components/Panel/Panel';
import StateContext from '../../contexts/state-context';
import { processInventory } from '../../processor/state-processor';
import merge from 'lodash/merge';

const initialState = {
    state: undefined,
    inventoryPassword: '',
    inventoryFileName: '',
    isStateModified: false,
    showNewInventoryDialog: false,
    showLoadInventoryDialog: false,
    showDownloadInventoryDialog: false,
    headerSaveButtonDisabled: true,
    currentGroup: 'global',
    index: 0
};

class InventoryManager extends Component {
    state = {
        ...initialState
    };

    constructor(props) {
        super(props);
    }

    onCreateNewState = (newState) => {
        let self = this;
        this.getModel(newState).then(function (models) {
            const model = merge.apply(null, [{}].concat(models));
            const state = { ...initialState };
            state.state = processInventory(model, undefined);
            state.headerSaveButtonDisabled = false;
            state.index = self.state.index + 1;
            self.setState(state);
            window.scrollTo(0, 0);
        })
    };

    getModel(model) {
        let promises = [];
        const pModel = fetch('models/' + model.value, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
        ).then(function (response) {
            return response.json();
        });
        promises.push(pModel);
        if (model.extras) {
            for (let extra of model.extras) {
                let pExtra = fetch('models/' + extra.value, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
                ).then(function (response) {
                    return response.json();
                });
                promises.push(pExtra);
            }
        }
        return Promise.all(promises);
    }

    onLoadState = (inventoryFileName, inventoryPassword, loadedState) => {
        const state = { ...initialState };
        state.inventoryFileName = inventoryFileName;
        state.inventoryPassword = inventoryPassword;
        state.state = loadedState;
        state.index = this.state.index + 1;
        this.setState(state, () => {
            this.setState({ headerSaveButtonDisabled: false });
            window.scrollTo(0, 0);
        });
    };

    onDownloadState = (inventoryFileName, inventoryPassword, state) => {
        this.setState({
            state,
            inventoryPassword,
            inventoryFileName,
            showDownloadInventoryDialog: false
        });
    };

    addHostGroupHandler = (group, hostIndex) => {
        const localState = {
            ...this.state.state
        };

        let key = Object.entries(localState.hosts).sort(([k1, v1],[k2, v2]) => v1.index - v2.index)[hostIndex][0];
        localState.hosts[key].groups.push({ name: group, properties: group !== undefined && localState.groups[group].properties.hostProperties !== undefined ? localState.groups[group].properties.hostProperties : {} });
        this.setState({ state: localState, isStateModified: true });
    };

    setHostGroupValueHandler = (hostIndex, index, group,) => {
        const localState = {
            ...this.state.state
        };

        let key = Object.entries(localState.hosts).sort(([k1, v1],[k2, v2]) => v1.index - v2.index)[hostIndex][0];
        localState.hosts[key].groups[index].name = group
        localState.hosts[key].groups[index].properties = localState.groups[group].properties.hostProperties !== undefined ? localState.groups[group].properties.hostProperties : {}
        this.setState({ state: localState, isStateModified: true });
    };

    deleteHostGroupHandler = (hostIndex, index) => {
        const localState = {
            ...this.state.state
        };

        let key = Object.entries(localState.hosts).sort(([k1, v1],[k2, v2]) => v1.index - v2.index)[hostIndex][0]
        localState.hosts[key].groups.splice(index, 1);
        this.setState({ state: localState, isStateModified: true });
    };

    addHostHandler = () => {
        const localState = {
            ...this.state.state
        };

        const min = Math.min(0, ...Object.entries(localState.hosts).map(([k, v]) => v.index));
        localState.hosts[''] = { 
            index: min - 1,
            groups: [{}] 
        };
        
        this.setState({ state: localState, isStateModified: true });
    };

    validateHostValueHandler = (index, value) => {
        const localState = {
            ...this.state.state
        };

        if (localState.hosts[value.trim()] !== undefined && Object.keys(localState.hosts)[index] !== value.trim()) {
            return { "invalid": "A host with hostname " + value.trim() + " has already been added. " };
        }
        return {};
    };

    setHostValueHandler = (index, value) => {
        const localState = {
            ...this.state.state
        };

        let oldKey = Object.entries(localState.hosts).sort(([k1, v1],[k2, v2]) => v1.index - v2.index)[index][0];
        localState.hosts[value.trim()] = localState.hosts[oldKey];
        delete localState.hosts[oldKey];
        this.setState({ state: localState, isStateModified: true });
    };

    deleteHostHandler = (index) => {
        const localState = {
            ...this.state.state
        };
        let oldKey = Object.entries(localState.hosts).sort(([k1, v1],[k2, v2]) => v1.index - v2.index)[index][0];
        delete localState.hosts[oldKey];
        this.setState({ state: localState, isStateModified: true });
    };

    addArrayElementHandler = (group, isAdvanced, name, hostIndex) => {
        const localState = {
            ...this.state.state
        };

        const property = this.getPropertyReference(localState, group, isAdvanced, name, hostIndex);
        property.elements.push('');
        this.setState({ state: localState, isStateModified: true });
    };

    deleteArrayElementHandler = (group, isAdvanced, name, index, hostIndex) => {
        const localState = {
            ...this.state.state
        };

        const property = this.getPropertyReference(localState, group, isAdvanced, name, hostIndex);
        property.elements.splice(index, 1);
        this.setState({ state: localState, isStateModified: true });
    };

    setArrayElementValueHandler = (group, isAdvanced, name, index, value, hostIndex) => {
        const localState = {
            ...this.state.state
        };

        const property = this.getPropertyReference(localState, group, isAdvanced, name, hostIndex);
        property.elements[index] = value;
        this.setState({ state: localState, isStateModified: true });
    };

    setPropertyValueHandler = (group, isAdvanced, name, value, hostIndex) => {
        const localState = {
            ...this.state.state
        };

        const property = this.getPropertyReference(localState, group, isAdvanced, name, hostIndex);
        property.currentValue = value;

        this.setState({ state: localState, isStateModified: true });
    };

    getPropertyReference(state, group, isAdvanced, name, hostIndex) {
        let localName = name;
        let property;
        let hostKey = hostIndex === undefined ? undefined : Object.keys(state.hosts)[hostIndex];
        let properties = group === 'global' ? state.global : (hostKey === undefined) ? state.groups[group].properties : state.hosts[hostKey].groups.find((g) => g.name === group).properties;
        if (isAdvanced) {
            properties = properties.advancedProperties;
        }

        if (localName.includes('.')) {
            const nameChain = name.split('.');
            let i = 1;
            property = properties[nameChain[0]];
            while (i < nameChain.length) {
                property = property.children[nameChain[i++]];
            }

        } else {
            property = properties[localName];
        }
        return property;
    };

    getPropertyDefaultReferenceHandler = (name) => {
        const localState = {
            ...this.state.state
        };
        let nameChain = name.split('.');
        let group = nameChain.shift();
        let isAdvanced = false;
        if(nameChain[0] === "advancedProperties"){
            isAdvanced = true;
            nameChain.shift()
        }
        return this.getPropertyReference(localState, group, isAdvanced, nameChain.join("."), undefined);
    };

    render = () => {
        return (
            <div className="p-2 h-100">
                <Header
                    onNewClick={() => this.setState({ showNewInventoryDialog: true })}
                    onLoadClick={() => this.setState({ showLoadInventoryDialog: true })}
                    onSaveClick={() => this.setState({ showDownloadInventoryDialog: true })}
                    saveButtonDisabled={this.state.headerSaveButtonDisabled}
                >
                    <NewInventoryDialog
                        show={this.state.showNewInventoryDialog}
                        onClose={() => this.setState({ showNewInventoryDialog: false })}
                        onNewClick={this.onCreateNewState}
                    />
                    <DownloadInventoryDialog
                        show={this.state.showDownloadInventoryDialog}
                        onDownload={this.onDownloadState}
                        password={this.state.inventoryPassword}
                        inventoryFileName={this.state.inventoryFileName}
                        state={this.state.state}
                        onClose={() => this.setState({ showDownloadInventoryDialog: false })}
                    />
                    <UploadInventoryDialog
                        show={this.state.showLoadInventoryDialog}
                        onClose={() => this.setState({ showLoadInventoryDialog: false })}
                        onUpload={this.onLoadState}
                    />
                </Header>
                <StateContext.Provider value={{
                    state: this.state.state,
                    addHost: this.addHostHandler,
                    validateHostValue: this.validateHostValueHandler,
                    setHostValue: this.setHostValueHandler,
                    deleteHost: this.deleteHostHandler,
                    addHostGroup: this.addHostGroupHandler,
                    setHostGroupValue: this.setHostGroupValueHandler,
                    deleteHostGroup: this.deleteHostGroupHandler,
                    addArrayElement: this.addArrayElementHandler,
                    deleteArrayElement: this.deleteArrayElementHandler,
                    setArrayElementValue: this.setArrayElementValueHandler,
                    setPropertyValue: this.setPropertyValueHandler,
                    getPropertyDefaultReference: this.getPropertyDefaultReferenceHandler
                }}>
                    <Panel key={this.state.index} state={this.state.state} currentGroup={this.state.currentGroup} onGroupSelect={(name) => this.setState({ currentGroup: name })} />
                </StateContext.Provider>
            </div>
        );
    }
}

export default InventoryManager;
