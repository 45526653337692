import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import StateContext from '../../../contexts/state-context';
import PreviewImage from './PreviewImage';
import ColorPreview from './ColorPreview';
import { imageTemplates } from './image-templates';

const Preview = () => {
    const branding = Object.assign({}, ...Object.entries(useContext(StateContext).state.global.global_branding.children).map(
        ([key, value], index) => {
            return {
                [key]: (value.currentValue === '') ? value.currentDefaultValue : value.currentValue
            };
        }));

    const splashScreen = String.format(imageTemplates.splashBackgroundTemplate, branding.theme_base_colour, branding.logo, branding.theme_primary_colour_1);
    const popupBackground = String.format(imageTemplates.popupBackgroundTemplate, branding.theme_base_colour, branding.theme_primary_colour_1);
    const onePanelBackground = String.format(imageTemplates.onePanelBackgroundTemplate, branding.theme_base_colour, branding.header_logo);
    const mainBackground = String.format(imageTemplates.mainBackgroundTemplate, branding.theme_base_colour, branding.header_logo, branding.theme_primary_colour_1, branding.theme_primary_colour_2);
    const exitBackground = String.format(imageTemplates.exitBackgroundTemplate, branding.theme_base_colour, branding.theme_primary_colour_1);

    return (
        <Form>
            <Row>
                <h6 className="preview-title form-control-plaintext text-muted">Backgrounds</h6>
            </Row>
            <Row>
                <Col>
                    <PreviewImage template={splashScreen} width="492" height="340" showPreview="true" title="Splash Screen" group="global" advanced="true" variableName="global_branding.backgrounds.splash_screen" />
                </Col>
                <Col>
                    <PreviewImage template={mainBackground} width="2400" height="1200" showPreview="true" title="Main Background" group="global" advanced="true" variableName="global_branding.backgrounds.main_panel" />
                </Col>
                <Col>
                    <PreviewImage template={onePanelBackground} width="2400" height="1200" showPreview="true" title="One Panel Background" group="global" advanced="true" variableName="global_branding.backgrounds.one_panel" />
                </Col>
                <Col>
                    <PreviewImage template={popupBackground} width="1600" height="1024" showPreview="true" title="Popup Background" group="global" advanced="true" variableName="global_branding.backgrounds.popup_panel" />
                </Col>
                <Col>
                    <PreviewImage template={exitBackground} width="297" height="182" showPreview="true" title="Exit Background" group="global" advanced="true" variableName="global_branding.backgrounds.exit_screen" />
                </Col>
            </Row>
            <Row>
                <h6 className="preview-title form-control-plaintext text-muted">Buttons</h6>
            </Row>
            <Row>
                <Col>
                    <PreviewImage template={String.format(imageTemplates.enabledButtonTemplate, branding.theme_primary_colour_1)} width="80" height="28" title="Default Button Enabled" group="global" advanced="true" variableName="global_branding.backgrounds.default_button_enabled" />
                    <PreviewImage template={String.format(imageTemplates.disabledButtonTemplate, branding.theme_primary_colour_1)} width="80" height="28" title="Default Button Disabled" group="global" advanced="true" variableName="global_branding.backgrounds.default_button_disabled" />
                    <PreviewImage template={String.format(imageTemplates.activeButtonTemplate, branding.theme_primary_colour_1)} width="80" height="28" title="Default Button Active" group="global" advanced="true" variableName="global_branding.backgrounds.default_button_active" />
                </Col>
                <Col>
                    <PreviewImage template={String.format(imageTemplates.enabledButtonTemplate, branding.theme_primary_colour_1)} width="110" height="28" title="Medium Button Enabled" group="global" advanced="true" variableName="global_branding.backgrounds.medium_default_button_enabled" />
                    <PreviewImage template={String.format(imageTemplates.disabledButtonTemplate, branding.theme_primary_colour_1)} width="110" height="28" title="Medium Button Disabled" group="global" advanced="true" variableName="global_branding.backgrounds.medium_default_button_disabled" />
                    <PreviewImage template={String.format(imageTemplates.activeButtonTemplate, branding.theme_primary_colour_1)} width="110" height="28" title="Medium Button Active" group="global" advanced="true" variableName="global_branding.backgrounds.medium_default_button_active" />
                </Col>
                <Col>
                    <PreviewImage template={String.format(imageTemplates.enabledButtonTemplate, branding.theme_primary_colour_1)} width="120" height="28" title="Large Button Enabled" group="global" advanced="true" variableName="global_branding.backgrounds.large_default_button_enabled" />
                    <PreviewImage template={String.format(imageTemplates.disabledButtonTemplate, branding.theme_primary_colour_1)} width="120" height="28" title="Large Button Disabled" group="global" advanced="true" variableName="global_branding.backgrounds.large_default_button_disabled" />
                    <PreviewImage template={String.format(imageTemplates.activeButtonTemplate, branding.theme_primary_colour_1)} width="120" height="28" title="Large Button Active" group="global" advanced="true" variableName="global_branding.backgrounds.large_default_button_active" />
                </Col>
                <Col>
                    <PreviewImage template={String.format(imageTemplates.enabledButtonTemplate, branding.theme_secondary_colour)} width="80" height="28" title="Warning Button Enabled" group="global" advanced="true" variableName="global_branding.backgrounds.warning_button_enabled" />
                    <PreviewImage template={String.format(imageTemplates.disabledButtonTemplate, branding.theme_secondary_colour)} width="80" height="28" title="Warning Button Disabled" group="global" advanced="true" variableName="global_branding.backgrounds.warning_button_disabled" />
                    <PreviewImage template={String.format(imageTemplates.activeButtonTemplate, branding.theme_secondary_colour)} width="80" height="28" title="Warning Button Active" group="global" advanced="true" variableName="global_branding.backgrounds.warning_button_active" />
                </Col>
                <Col>
                    <PreviewImage template={String.format(imageTemplates.enabledButtonTemplate, branding.theme_gray_colour)} width="80" height="28" title="Secondary Button Enabled" group="global" advanced="true" variableName="global_branding.backgrounds.secondary_button_enabled" />
                    <PreviewImage template={String.format(imageTemplates.disabledButtonTemplate, branding.theme_gray_colour)} width="80" height="28" title="Secondary Button Disabled" group="global" advanced="true" variableName="global_branding.backgrounds.secondary_button_disabled" />
                    <PreviewImage template={String.format(imageTemplates.activeButtonTemplate, branding.theme_gray_colour)} width="80" height="28" title="Secondary Button Active" group="global" advanced="true" variableName="global_branding.backgrounds.secondary_button_active" />
                </Col>
            </Row>
            <Row>
                <h6 className="preview-title form-control-plaintext text-muted">Colours</h6>
            </Row>
            <Row>
                <Col>
                    <ColorPreview color={branding.theme_secondary_colour} title="Link Text Colour" group="global" advanced="true" variableName="global_branding.colours.default_link_colour" />
                </Col>
                <Col>
                    <ColorPreview color="#FE000C" title="Error Text Colour" group="global" advanced="true" variableName="global_branding.colours.default_warning_colour" />
                </Col>
                <Col>
                    <ColorPreview color="#FFFFFF" title="Button Text Colour" group="global" advanced="true" variableName="global_branding.colours.button_text_colour" />
                </Col>
                <Col>
                    <ColorPreview color="#FFFFFF" title="Selected Button Text Colour" group="global" advanced="true" variableName="global_branding.colours.button_selected_text_colour" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ColorPreview color="#FFFFFF" title="Rollover Button Text Colour" group="global" advanced="true" variableName="global_branding.colours.button_rollover_text_colour" />
                </Col>
                <Col>
                    <ColorPreview color="#9AA1A9" title="Disabled Button Text Colour" group="global" advanced="true" variableName="global_branding.colours.button_disabled_text_colour" />
                </Col>
                <Col>
                    <ColorPreview color="#000000" title="Menu Text Colour" group="global" advanced="true" variableName="global_branding.colours.menu_font_colour" />
                </Col>
                <Col>
                    <ColorPreview color={branding.theme_primary_colour_1} title="Rollover Menu Text Colour" group="global" advanced="true" variableName="global_branding.colours.menu_rollover_colour" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ColorPreview color={branding.theme_secondary_colour} title="Active Menu Text Colour" group="global" advanced="true" variableName="global_branding.colours.menu_active_colour" />
                </Col>
                <Col>
                    <ColorPreview color={branding.theme_primary_colour_1} title="Table Header Colour" group="global" advanced="true" variableName="global_branding.colours.table_header_colour" />
                </Col>
                <Col>
                    <ColorPreview color={String.format("color-mix(in srgb, {0}, white 70%)", branding.theme_primary_colour_1)} title="Selected Table Row Colour" group="global" advanced="true" variableName="global_branding.colours.table_selected_row_colour" />
                </Col>
                <Col>
                    <ColorPreview color={String.format("color-mix(in srgb, {0}, white 90%)", branding.theme_primary_colour_1)} title="Unfocused Selected Table Row Colour" group="global" advanced="true" variableName="global_branding.colours.table_unfocused_row_colour" />
                </Col>
            </Row>
        </Form>
    )
};

export default Preview;
