import React from 'react';
import ContainerProperty from './ContainerProperty/ContainerProperty';
import ArrayProperty from './ArrayProperty/ArrayProperty';
import Property from './Property/Property';

const propertiesListItem = (props) => {
    let property = null;
    if (props.property.isContainer) {
        property = <ContainerProperty
            group={props.group}
            name={props.name}
            property={props.property}
            advanced={props.advanced}
        />
    } else if (props.property.isArray) {
        property = <ArrayProperty
            group={props.group}
            name={props.name}
            property={props.property}
            advanced={props.advanced}
            hostIndex={props.hostIndex}
        />
    } else {
        property = <Property
            group={props.group}
            name={props.name}
            property={props.property}
            advanced={props.advanced}
            hostIndex={props.hostIndex}
        />
    }

    return property;
};

export default propertiesListItem;
