import {
  isEncrypted
} from "../utility/util";
import ansible from "../encryption/ansible";
import {
  Encrypted
} from "../model/vaultType";

export function processInventory(modelDefinition, inventory, state = undefined, password = undefined, callback = undefined) {
  let hasCurrentState = true;
  if (state === undefined) {
    hasCurrentState = false;
    state = {
      hosts: {}
    }
  }

  if (!!!state.global) {
    state = {
      ...state,
      global: {

      }
    }
  }

  if (!!callback) {
    callback('Adding global properties');
  }

  Object.keys(modelDefinition.global)
    .map((name => name.trim()))
    .filter(name => name !== 'advanced_properties')
    .map((name) => {
      const model = modelDefinition.global[name];
      if (hasCurrentState) {
        let currentPropertyState = state.global[name];
        if (!currentPropertyState) {
          if (inventory !== undefined) {
            currentPropertyState = createNewProperty(model, inventory.all.vars[name], password);
          }
        }
        return {
          name: name,
          value: currentPropertyState
        };
      } else {
        return {
          name: name,
          value: createNewProperty(model, inventory !== undefined ? inventory.all.vars[name] != null ? inventory.all.vars[name] : undefined : undefined, password)
        };
      }
    }).forEach((property) => {
      state = {
        ...state,
        global: {
          ...state.global,
          [property.name]: property.value
        }
      };
    });

  Object.keys(modelDefinition.global.advanced_properties)
    .map((name => name.trim()))
    .map((name) => {
      const model = modelDefinition.global.advanced_properties[name];
      if (hasCurrentState) {
        let currentPropertyState = state.global.advancedProperties[name];
        if (!currentPropertyState) {
          if (inventory !== undefined) {
            currentPropertyState = createNewProperty(model, inventory.all.vars[name], password);
          }
        }
        return {
          name: name,
          value: currentPropertyState
        };
      } else {
        return {
          name: name,
          value: createNewProperty(model, inventory !== undefined ? inventory.all.vars[name] != null ? inventory.all.vars[name] : undefined : undefined, password)
        };
      }
    }).forEach((property) => {
      if (!!!state.global.advancedProperties) {
        state = {
          ...state,
          global: {
            ...state.global,
            advancedProperties: {

            }
          }
        }
      }

      state = {
        ...state,
        global: {
          ...state.global,
          advancedProperties: {
            ...state.global.advancedProperties,
            [property.name]: property.value
          }
        }
      };
    });

  if (!!!state.groups) {
    state = {
      ...state,
      groups: {}
    };
  }

  Object.keys(modelDefinition.groups)
    .map(groupName => groupName.trim())
    .forEach(groupName => {
      let groupRequired = true;
      if (hasCurrentState && !state.groups[groupName]) {
        groupRequired = false;
      }
      if (groupRequired) {
        if (!!callback) {
          callback(`Adding ${groupName} group hosts and properties`);
        }

        const group = modelDefinition.groups[groupName].properties !== undefined ? modelDefinition.groups[groupName].properties : {};
        if (!!!state.groups[groupName]) {
          state = {
            ...state,
            groups: {
              ...state.groups,
              [groupName]: {
                label: modelDefinition.groups[groupName].label,
                properties: {

                }
              }
            }
          }
        }

        if (inventory !== undefined) {
          if (inventory.all.children[groupName] !== undefined) {
            if (inventory.all.children[groupName].hosts !== undefined) {
              Object.keys(inventory.all.children[groupName].hosts)
                .map(name => name.trim())
                .forEach(name => {
                  if (state.hosts[name] === undefined) {
                    state = {
                      ...state,
                      hosts: {
                        ...state.hosts,
                        [name]: {
                          index: Object.keys(state.hosts).length,
                          groups: [
                          ]
                        }
                      }
                    };
                  }
                  state = {
                    ...state,
                    hosts: {
                      ...state.hosts,
                      [name]: {
                        index: Object.keys(state.hosts).length,
                        ...state.hosts[name],
                        groups: [
                          ...state.hosts[name].groups,
                          {
                            name: groupName,
                            properties: {
                            }
                          }
                        ]
                      }
                    },
                    groups: {
                      ...state.groups,
                      [groupName]: {
                        ...state.groups[groupName]
                      }
                    }
                  };

                  if (!!modelDefinition.groups[groupName].properties && !!modelDefinition.groups[groupName].properties.host_properties) {
                    Object.keys(modelDefinition.groups[groupName].properties.host_properties)
                      .map((propName => propName.trim()))
                      .map((propName) => {
                        const model = modelDefinition.groups[groupName].properties.host_properties[propName];
                        return {
                          name: propName,
                          value: createNewProperty(model, inventory.all.children[groupName].hosts[name][propName] !== null && inventory.all.children[groupName].hosts[name][propName] !== undefined ? inventory.all.children[groupName].hosts[name][propName] : inventory.all.children[groupName] !== undefined && inventory.all.children[groupName].vars !== undefined ? inventory.all.children[groupName].vars[propName] != null ? inventory.all.children[groupName].vars[propName] : undefined : undefined, password)
                        };
                      })
                      .forEach(property => {
                        state.hosts[name].groups.find((g) => g.name === groupName).properties[property.name] = property.value;
                      });
                  }
                });
            }
            if (inventory.all.children[groupName].children !== undefined) {
              Object.keys(inventory.all.children[groupName].children[groupName + '_side_a'].hosts)
                .map(name => name.trim())
                .forEach(name => {
                  if (state.hosts[name] === undefined) {
                    state = {
                      ...state,
                      hosts: {
                        ...state.hosts,
                        [name]: {
                          index: Object.keys(state.hosts).length,
                          groups: [
                          ]
                        }
                      }
                    };
                  }
                  state = {
                    ...state,
                    hosts: {
                      ...state.hosts,
                      [name]: {
                        index: Object.keys(state.hosts).length,
                        ...state.hosts[name],
                        groups: [
                          ...state.hosts[name].groups,
                          {
                            name: groupName,
                            properties: {
                            }
                          }
                        ]
                      }
                    },
                    groups: {
                      ...state.groups,
                      [groupName]: {
                        ...state.groups[groupName]
                      }
                    }
                  };
                  if (!!modelDefinition.groups[groupName].properties && !!modelDefinition.groups[groupName].properties.host_properties) {
                    Object.keys(modelDefinition.groups[groupName].properties.host_properties)
                      .map((propName => propName.trim()))
                      .map((propName) => {
                        const model = modelDefinition.groups[groupName].properties.host_properties[propName];
                        return {
                          name: propName,
                          value: createNewProperty(model, inventory.all.children[groupName].children[groupName + '_side_a'].hosts[name] != null && inventory.all.children[groupName].children[groupName + '_side_a'].hosts[name][propName] !== undefined ? inventory.all.children[groupName].children[groupName + '_side_a'].hosts[name][propName] : inventory.all.children[groupName] !== undefined && inventory.all.children[groupName].vars !== undefined ? inventory.all.children[groupName].vars[propName] != null ? inventory.all.children[groupName].vars[propName] : undefined : undefined, password)
                        };
                      })
                      .forEach(property => {
                        state.hosts[name].groups.find((g) => g.name === groupName).properties[property.name] = property.value;
                      });
                  }
                });
              if (inventory.all.children[groupName].children[groupName + '_side_b'] !== undefined) {
                Object.keys(inventory.all.children[groupName].children[groupName + '_side_b'].hosts)
                  .map(name => name.trim())
                  .forEach(name => {
                    if (state.hosts[name] === undefined) {
                      state = {
                        ...state,
                        hosts: {
                          ...state.hosts,
                          [name]: {
                            index: Object.keys(state.hosts).length,
                            groups: [
                            ]
                          }
                        }
                      };
                    }
                    state = {
                      ...state,
                      hosts: {
                        ...state.hosts,
                        [name]: {
                          index: Object.keys(state.hosts).length,
                          ...state.hosts[name],
                          groups: [
                            ...state.hosts[name].groups,
                            {
                              name: groupName,
                              properties: {
                              }
                            }
                          ]
                        }
                      },
                      groups: {
                        ...state.groups,
                        [groupName]: {
                          ...state.groups[groupName]
                        }
                      }
                    };
                    if (!!modelDefinition.groups[groupName].properties && !!modelDefinition.groups[groupName].properties.host_properties) {
                      Object.keys(modelDefinition.groups[groupName].properties.host_properties)
                        .map((propName => propName.trim()))
                        .map((propName) => {
                          const model = modelDefinition.groups[groupName].properties.host_properties[propName];
                          return {
                            name: propName,
                            value: createNewProperty(model, inventory.all.children[groupName].children[groupName + '_side_b'].hosts[name] != null && inventory.all.children[groupName].children[groupName + '_side_b'].hosts[name][propName] !== undefined ? inventory.all.children[groupName].children[groupName + '_side_b'].hosts[name][propName] : inventory.all.children[groupName] !== undefined && inventory.all.children[groupName].vars !== undefined ? inventory.all.children[groupName].vars[propName] != null ? inventory.all.children[groupName].vars[propName] : undefined : undefined, password)
                          };
                        })
                        .forEach(property => {
                          state.hosts[name].groups.find((g) => g.name === groupName).properties[property.name] = property.value;
                        });
                    }
                  });
              }
            }
          }
        }

        Object.keys(group)
          .map(name => name.trim())
          .filter(name => name !== 'advanced_properties' && name !== 'host_properties')
          .map(name => {
            const model = modelDefinition.groups[groupName].properties[name];
            if (hasCurrentState) {
              let currentPropertyState = state.groups[groupName].properties[name];
              if (!currentPropertyState) {
                if (inventory !== undefined) {
                  currentPropertyState = createNewProperty(model, inventory.all.children[groupName].vars[name], password);
                }
              }
              return {
                name: name,
                value: currentPropertyState
              };
            } else {
              return {
                name: name,
                value: createNewProperty(model, inventory !== undefined ? (inventory.all.children !== undefined && inventory.all.children[groupName] !== undefined && inventory.all.children[groupName].vars !== undefined ? inventory.all.children[groupName].vars[name] != null ? inventory.all.children[groupName].vars[name] : undefined : undefined) : undefined, password)
              };
            }
          })
          .forEach(property => {
            state = {
              ...state,
              groups: {
                ...state.groups,
                [groupName]: {
                  ...state.groups[groupName],
                  properties: {
                    ...state.groups[groupName].properties,
                    [property.name]: property.value
                  }
                }
              }
            };
          });

        if (!!modelDefinition.groups[groupName].properties && !!modelDefinition.groups[groupName].properties.advanced_properties) {
          Object.keys(modelDefinition.groups[groupName].properties.advanced_properties)
            .map((name => name.trim()))
            .map((name) => {
              const model = modelDefinition.groups[groupName].properties.advanced_properties[name];
              if (hasCurrentState) {
                let currentPropertyState = state.groups[groupName].properties.advancedProperties[name];
                if (!currentPropertyState) {
                  if (inventory !== undefined) {
                    currentPropertyState = createNewProperty(model, inventory.all.children[groupName].vars[name], password);
                  }
                }
                return {
                  name: name,
                  value: currentPropertyState
                };
              } else {
                return {
                  name: name,
                  value: createNewProperty(model, inventory !== undefined ? (inventory.all.children !== undefined && inventory.all.children[groupName] !== undefined && inventory.all.children[groupName].vars !== undefined ? inventory.all.children[groupName].vars[name] != null ? inventory.all.children[groupName].vars[name] : undefined : undefined) : undefined, password)
                };
              }
            }).forEach((property) => {
              if (!!!state.groups[groupName].properties.advancedProperties) {
                state = {
                  ...state,
                  groups: {
                    ...state.groups,
                    [groupName]: {
                      ...state.groups[groupName],
                      properties: {
                        ...state.groups[groupName].properties,
                        advancedProperties: {}
                      }
                    }
                  }
                };
              }

              state = {
                ...state,
                groups: {
                  ...state.groups,
                  [groupName]: {
                    ...state.groups[groupName],
                    properties: {
                      ...state.groups[groupName].properties,
                      advancedProperties: {
                        ...state.groups[groupName].properties.advancedProperties,
                        [property.name]: property.value
                      }
                    }
                  }
                }
              };
            });
        }

        if (!!modelDefinition.groups[groupName].properties && !!modelDefinition.groups[groupName].properties.host_properties) {
          Object.keys(modelDefinition.groups[groupName].properties.host_properties)
            .map((name => name.trim()))
            .map((name) => {
              const model = modelDefinition.groups[groupName].properties.host_properties[name];
              if (hasCurrentState) {
                let currentPropertyState = state.groups[groupName].properties.host_properties[name];
                if (!currentPropertyState) {
                  if (inventory !== undefined) {
                    currentPropertyState = createNewProperty(model, undefined, password);
                  }
                }
                return {
                  name: name,
                  value: currentPropertyState
                };
              } else {
                return {
                  name: name,
                  value: createNewProperty(model, undefined, password)
                };
              }
            }).forEach((property) => {
              if (!!!state.groups[groupName].properties.hostProperties) {
                state = {
                  ...state,
                  groups: {
                    ...state.groups,
                    [groupName]: {
                      ...state.groups[groupName],
                      properties: {
                        ...state.groups[groupName].properties,
                        hostProperties: {}
                      }
                    }
                  }
                };
              }

              state = {
                ...state,
                groups: {
                  ...state.groups,
                  [groupName]: {
                    ...state.groups[groupName],
                    properties: {
                      ...state.groups[groupName].properties,
                      hostProperties: {
                        ...state.groups[groupName].properties.hostProperties,
                        [property.name]: property.value
                      }
                    }
                  }
                }
              };
            });
        }
      }
    });

  if (!!callback) {
    callback('');
  }

  return state;
}

export const createNewProperty = (model, inventory, password) => {
  let property = {};

  if (model.isContainer) {
    property = {
      ...property,
      isContainer: true,
      label: model.label,
      description: model.description,
      isHidden: model.isHidden,
      children: {}
    };

    Object.keys(model.children).map(name => {
      return name.trim();
    }).map(name => {
      property = {
        ...property,
        children: {
          ...property.children,
          [name]: createNewProperty(model.children[name], inventory !== undefined ? inventory[name] : undefined, password)
        }
      };
      return true;
    });
  } else if (model.isArray) {
    property = {
      isArray: true,
      elements: [],
      label: model.label,
      description: model.description,
      type: model.type,
      values: model.values,
      isHidden: model.isHidden
    };

    if (inventory !== undefined) {
      inventory.map((name) => {
        return name.trim();
      }).map(name => {
        property.elements.push(name);
        return true;
      });
    }
  } else {
    property = {
      ...property,
      description: model.description,
      control_type: 'control_type' in model ? model.control_type : 'textinput',
      label: model.label
    };

    if (property.control_type === 'lov') {
      property = {
        ...property,
        values: model.values
      }
    }

    if ('useDefault' in model) {
      property = {
        ...property,
        useDefault: model.useDefault
      }
    }

    if ('isOptional' in model) {
      property = {
        ...property,
        isOptional: model.isOptional
      }
    }

    if ('isObsolete' in model) {
      property = {
        ...property,
        isObsolete: model.isObsolete
      }
    }

    if ('isEncrypted' in model) {
      property = {
        ...property,
        isEncrypted: model.isEncrypted
      }
    }

    if ('isAuto' in model) {
      property = {
        ...property,
        isAuto: model.isAuto
      }
    }

    if ('default' in model) {
      property = {
        ...property,
        modelDefaultValue: model.default,
        currentDefaultValue: (!model.isAuto && model.default !== 'auto') ? model.default : '',
        isDefaultModified: false
      }
    }

    if ('isHidden' in model) {
      property = {
        ...property,
        isHidden: model.isHidden
      }
    }

    if ('reference' in model) {
      property = {
        ...property,
        reference: model.reference
      }
    }

    if ('type' in model) {
      property = {
        ...property,
        type: model.type
      }
    }

    if ('isReadOnly' in model) {
      property = {
        ...property,
        isReadOnly: model.isReadOnly
      }
    }

    if ('forceDefault' in model) {
      property = {
        ...property,
        forceDefault: model.forceDefault,
        isDefaultModified: false
      }
    }

    if ('accepts' in model) {
      property = {
        ...property,
        accepts: model.accepts
      }
    }

    if ('showPreview' in model) {
      property = {
        ...property,
        showPreview: model.showPreview
      }
    }

    if ('previewBackground' in model) {
      property = {
        ...property,
        previewBackground: model.previewBackground
      }
    }

    if ('pattern' in model) {
      property = {
        ...property,
        pattern: model.pattern,
        invalidPatternText: model.invalidPatternText ? model.invalidPatternText : 'Value does not match the regular expression ' + model.pattern
      }
    }

    if ('min' in model) {
      property = {
        ...property,
        min: model.min
      }
    }

    if ('max' in model) {
      property = {
        ...property,
        max: model.max
      }
    }

    if ((typeof inventory !== 'boolean' && !!inventory) || typeof inventory === 'boolean') {
      if (isEncrypted(inventory)) {
        let value = inventory.data;
        if (password !== undefined && password.trim() !== '') {
          value = ansible.decrypt(inventory.data, password);
        }
        property = {
          ...property,
          stateValue: value,
          currentValue: model.forceDefault ? model.default: value,
          isDefaultModified: property.isDefaultModified || (model.forceDefault ? model.default !== value: false),
          isValueModified: false,
          isEncrypted: true
        }
      } else {
        property = {
          ...property,
          stateValue: inventory,
          currentValue: model.forceDefault ? model.default: inventory,
          isDefaultModified: property.isDefaultModified || (model.forceDefault ? model.default !== inventory: false),
          isValueModified: false,
          isEncrypted: property.isEncrypted
        }
      }
    } else {
      if ('default' in model) {
        property = {
          ...property,
          stateValue: (property.useDefault === undefined || property.useDefault) ? property.currentDefaultValue : '',
          currentValue: (property.useDefault === undefined || property.useDefault) ? property.currentDefaultValue : '',
          isValueModified: false
        }
      } else {
        property = {
          ...property,
          stateValue: '',
          currentValue: '',
          isValueModified: false
        }
      }
    }
  }

  return property;
};

export const validateEncryption = (inventory, password) => {
  let valid = true;

  for (let name of Object.keys(inventory.all.vars)) {
    name = name.trim();
    if (isEncrypted(inventory.all.vars[name])) {
      try {
        if (!!password && password.trim() !== '') {
          ansible.decrypt(inventory.all.vars[name].data, password);
        } else {
          valid = false;
          break;
        }
      } catch (e) {
        valid = false;
        break;
      }
    }
  }

  if (valid) {
    for (let groupName of Object.keys(inventory.all.children)) {
      if (!!inventory.all.children[groupName].vars) {
        for (let name of Object.keys(inventory.all.children[groupName].vars)) {
          if (isEncrypted(inventory.all.children[groupName].vars[name])) {
            try {
              if (!!password && password.trim() !== '') {
                ansible.decrypt(inventory.all.children[groupName].vars[name].data, password);
              } else {
                valid = false;
                break;
              }
            } catch (e) {
              valid = false;
              break;
            }
          }
        }
      }
      if (!valid) {
        break;
      }
    }
  }

  return valid;
};

export const getInventory = (state, password, callback) => {
  let inventory = {
    all: {
      vars: {

      },
      children: {

      }
    }
  };

  const total = totalProperties(state);
  let count = 0;
  let progress = 25;

  if (!!callback) {
    callback({
      progress: 25,
      message: 'Processing Global Properties'
    });
  }

  Object.keys(state.global)
    .filter(name => name !== 'advanced_properties')
    .map(name => name.trim())
    .forEach(name => {
      let property = state.global[name];

      count++;
      progress = updateProgressIfNecessary(25, total, count, progress, 'Extracting global properties', callback);

      if (property.isContainer) {
        let result = processContainerProperty(property, password);
        if (result.hasValue) {
          inventory = {
            ...inventory,
            all: {
              ...inventory.all,
              vars: {
                ...inventory.all.vars,
                [name]: {
                  ...inventory.all.vars[name],
                  ...result.inventory
                }
              }
            }
          }
        }
      } else if (property.isArray) {
        if (property.elements.length > 0) {
          let array = buildInventoryArray(property);

          if (array.length > 0) {
            inventory = {
              ...inventory,
              all: {
                ...inventory.all,
                vars: {
                  ...inventory.all.vars,
                  [name]: array
                }
              }
            };
          }
        }
      } else {
        if (state.global[name].currentValue === undefined) return true;
        if (typeof state.global[name].currentValue === 'string' && state.global[name].currentValue.trim() === '') return true;

        inventory = {
          ...inventory,
          all: {
            ...inventory.all,
            vars: {
              ...inventory.all.vars,
              [name]: property.isEncrypted !== undefined && property.isEncrypted && password !== undefined && password.trim() !== '' ? new Encrypted(ansible.encrypt(property.currentValue, password.trim())) : property.currentValue
            }
          }
        };
      }
    });

  let majorVersion = parseInt(state.global.inventory_version.currentValue.split(".")[0]);
  let minorVersion = parseInt(state.global.inventory_version.currentValue.split(".")[1]);

  Object.keys(state.global.advancedProperties)
    .map(name => name.trim())
    .forEach(name => {
      let property = state.global.advancedProperties[name];

      count++;
      progress = updateProgressIfNecessary(25, total, count, progress, 'Extracting global properties', callback);

      if (property.isContainer) {
        let result = processContainerProperty(property, password);
        if (result.hasValue) {
          inventory = {
            ...inventory,
            all: {
              ...inventory.all,
              vars: {
                ...inventory.all.vars,
                [name]: {
                  ...inventory.all.vars[name],
                  ...result.inventory
                }
              }
            }
          }
        }
      } else if (property.isArray) {
        let array = buildInventoryArray(property);

        if (array.length > 0) {
          inventory = {
            ...inventory,
            all: {
              ...inventory.all,
              vars: {
                ...inventory.all.vars,
                [name]: array
              }
            }
          };
        }
      } else {
        if (state.global.advancedProperties[name].currentValue === undefined) return true;
        if (typeof state.global.advancedProperties[name].currentValue === 'string' && state.global.advancedProperties[name].currentValue.trim() === '') return true;

        inventory = {
          ...inventory,
          all: {
            ...inventory.all,
            vars: {
              ...inventory.all.vars,
              [name]: property.isEncrypted !== undefined && property.isEncrypted && password !== undefined && password.trim() !== '' ? new Encrypted(ansible.encrypt(property.currentValue, password.trim())) : property.currentValue
            }
          }
        };
      }
    });

  Object.keys(state.groups)
    .filter((groupName) => Object.entries(state.hosts).findIndex(([k, v]) => v.groups.findIndex((g) => g.name === groupName) >= 0) >= 0)
    .map(groupName => groupName.trim())
    .forEach(groupName => {
      let group = state.groups[groupName];
      if (!!callback) {
        callback(`Preparing ${groupName} Inventory`);
      }

      inventory = {
        ...inventory,
        all: {
          ...inventory.all,
          children: {
            ...inventory.all.children,
            [groupName]: {
              hosts: {

              },
              children: {
                [groupName + '_side_a']: {
                  hosts: {

                  }
                },
                [groupName + '_side_b']: {
                  hosts: {

                  }
                }
              },
              vars: {

              }
            }
          }
        }
      };

      Object.entries(state.hosts).filter(([k, v]) => v.groups.findIndex((g) => g.name === groupName) >= 0).forEach(([k, v], index) => {
        let hostName = k;
        let hostVars = Object.assign({}, ...Object.keys(v.groups.find((g) => g.name === groupName).properties).map(p => {
          let property = v.groups.find((g) => g.name === groupName).properties[p];
          let value = property.isEncrypted !== undefined && property.isEncrypted && password !== undefined && password.trim() !== '' ? new Encrypted(ansible.encrypt(property.currentValue, password.trim())) : property.currentValue;
          return {
            [p]: value
          };
        }).filter(o => Object.values(o).every(v => v !== undefined && v !== '')));
        if (hostName.trim() !== 'localhost' && !(majorVersion === 8 && minorVersion < 12)) {
          inventory = {
            ...inventory,
            all: {
              ...inventory.all,
              children: {
                ...inventory.all.children,
                [groupName]: {
                  ...inventory.all.children[groupName],
                  children: {
                    ...inventory.all.children[groupName].children,
                    [groupName + ((index % 2 === 0) ? '_side_a' : '_side_b')]: {
                      ...inventory.all.children[groupName].children[groupName + ((index % 2 === 0) ? '_side_a' : '_side_b')],
                      hosts: {
                        ...inventory.all.children[groupName].children[groupName + ((index % 2 === 0) ? '_side_a' : '_side_b')].hosts,
                        [hostName]: hostVars
                      }
                    }
                  }
                }
              }
            }
          };
        } else {
          inventory = {
            ...inventory,
            all: {
              ...inventory.all,
              children: {
                ...inventory.all.children,
                [groupName]: {
                  ...inventory.all.children[groupName],
                  hosts: {
                    ...inventory.all.children[groupName].hosts,
                    [hostName]: hostVars
                  }
                }
              }
            }
          };
        }
      });

      if (Object.keys(inventory.all.children[groupName].children[groupName + '_side_b'].hosts).length === 0) {
        delete inventory.all.children[groupName].children[groupName + '_side_b'];
      }

      if (Object.keys(inventory.all.children[groupName].hosts).length === 0) {
        delete inventory.all.children[groupName].hosts;
      } else {
        delete inventory.all.children[groupName].children;
      }


      Object.keys(group.properties)
        .filter(name => name !== 'advanced_properties' && name !== 'host_properties')
        .map(name => name.trim())
        .forEach(name => {
          let property = group.properties[name];

          count++;
          progress = updateProgressIfNecessary(25, total, count, progress, `Extracting '${groupName}' group properties`, callback);

          if (property.isContainer) {
            let result = processContainerProperty(property, password);
            if (result.hasValue) {
              inventory = {
                ...inventory,
                all: {
                  ...inventory.all,
                  children: {
                    ...inventory.all.children,
                    [groupName]: {
                      ...inventory.all.children[groupName],
                      vars: {
                        ...inventory.all.children[groupName].vars,
                        [name]: {
                          ...inventory.all.children[groupName].vars[name],
                          ...result.inventory
                        }
                      }
                    }
                  }
                }
              }
            }
          } else if (property.isArray) {
            let array = buildInventoryArray(property);

            if (array.length > 0) {
              inventory = {
                ...inventory,
                all: {
                  ...inventory.all,
                  children: {
                    ...inventory.all.children,
                    [groupName]: {
                      ...inventory.all.children[groupName],
                      vars: {
                        ...inventory.all.children[groupName].vars,
                        [name]: array
                      }
                    }
                  }
                }
              };
            }
          } else {
            if (group.properties[name].currentValue === undefined) return true;
            if (typeof group.properties[name].currentValue === 'string' && group.properties[name].currentValue.trim() === '') return true;

            inventory = {
              ...inventory,
              all: {
                ...inventory.all,
                children: {
                  ...inventory.all.children,
                  [groupName]: {
                    ...inventory.all.children[groupName],
                    vars: {
                      ...inventory.all.children[groupName].vars,
                      [name]: property.isEncrypted !== undefined && property.isEncrypted && password !== undefined && password.trim() !== '' ? new Encrypted(ansible.encrypt(property.currentValue, password.trim())) : property.currentValue
                    }
                  }
                }
              }
            };
          }
        });

      if (group.properties.advancedProperties !== undefined) {
        Object.keys(group.properties.advancedProperties)
          .map(name => name.trim())
          .forEach(name => {
            let property = group.properties.advancedProperties[name];

            count++;
            progress = updateProgressIfNecessary(25, total, count, progress, `Extracting '${groupName}' group properties`, callback);

            if (property.isContainer) {
              let result = processContainerProperty(property, password);
              if (result.hasValue) {
                inventory = {
                  ...inventory,
                  all: {
                    ...inventory.all,
                    children: {
                      ...inventory.all.children,
                      [groupName]: {
                        ...inventory.all.children[groupName],
                        vars: {
                          ...inventory.all.children[groupName].vars,
                          [name]: {
                            ...inventory.all.children[groupName].vars[name],
                            ...result.inventory
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else if (property.isArray) {
              let array = buildInventoryArray(property);

              if (array.length > 0) {
                inventory = {
                  ...inventory,
                  all: {
                    ...inventory.all,
                    children: {
                      ...inventory.all.children,
                      [groupName]: {
                        ...inventory.all.children[groupName],
                        vars: {
                          ...inventory.all.children[groupName].vars,
                          [name]: array
                        }
                      }
                    }
                  }
                };
              }
            } else {
              if (group.properties.advancedProperties[name].currentValue === undefined) return true;
              if (typeof group.properties.advancedProperties[name].currentValue === 'string' && group.properties.advancedProperties[name].currentValue.trim() === '') return true;

              inventory = {
                ...inventory,
                all: {
                  ...inventory.all,
                  children: {
                    ...inventory.all.children,
                    [groupName]: {
                      ...inventory.all.children[groupName],
                      vars: {
                        ...inventory.all.children[groupName].vars,
                        [name]: property.isEncrypted !== undefined && property.isEncrypted && password !== undefined && password.trim() !== '' ? new Encrypted(ansible.encrypt(property.currentValue, password.trim())) : property.currentValue
                      }
                    }
                  }
                }
              };
            }
          });
      }
    });


  if (!!callback) {
    callback('');
  }
  return inventory;
};

const buildInventoryArray = (property) => {
  let array = [];
  property.elements.map(value => {
    if (property.type === 'string') {
      if (value && value.trim() !== '') {
        array.push(value);
      }
    } else {
      let obj = {};
      Object.keys(value).map(name => {
        obj = {
          ...obj,
          [name]: value[name].currentValue
        };
        return null;
      });
      array.push(obj);
    }
    return null;
  });

  return array;
};

const totalProperties = (state) => {
  let total = Object.keys(state.global).filter(name => name !== 'advancedProperties').length + Object.keys(state.global.advancedProperties).length;
  for (let groupName of Object.keys(state.groups)) {
    total += Object.keys(state.groups[groupName].properties).filter(name => name !== 'advancedProperties' && name !== 'hostProperties').length + (state.groups[groupName].properties.advancedProperties !== undefined ? Object.keys(state.groups[groupName].properties.advancedProperties).length : 0);
  }

  return total;
};

const updateProgressIfNecessary = (base, total, count, progress, message, callback) => {
  const tmp = Math.floor(25 * count / total) + base;
  if (tmp !== undefined && tmp !== progress) {
    progress = tmp;
    if (!!callback) {
      callback({
        progress: progress,
        message: message
      });
    }
  }

  return progress;
};

export const validateState = (state, callback = undefined) => {
  if (!!callback) {
    callback({
      progress: 0,
      message: 'Validating global properties'
    });
  }

  const total = totalProperties(state);
  let count = 0;
  let progress = 0;

  for (let name of Object.keys(state.global)) {
    count++;
    progress = updateProgressIfNecessary(0, total, count, progress, 'Validating global properties', callback);

    if (name !== 'advancedProperties') {
      const property = state.global[name];
      if (property.isContainer) {
        const result = validateContainerProperty('Global', property.label, property);
        if (result != null) {
          return result;
        }
      } else {
        const result = validateValue('Global', property.label, property);
        if (result != null) {
          return result;
        }
      }
    }
  }

  for (let name of Object.keys(state.global.advancedProperties)) {
    count++;
    progress = updateProgressIfNecessary(0, total, count, progress, 'Validating global properties', callback);

    const property = state.global.advancedProperties[name];
    if (property.isContainer) {
      const result = validateContainerProperty('Global', property.label, property);
      if (result != null) {
        return result;
      }
    } else {
      const result = validateValue('Global', property.label, property);
      if (result != null) {
        return result;
      }
    }
  }

  for (let groupName of Object.keys(state.groups)) {
    if (!!callback) {
      callback(`Validating '${groupName}' properties`);
    }

    if (Object.keys(state.hosts).filter((hostName) => state.hosts[hostName].groups.findIndex((group) => group.name === groupName) > -1).length > 0) {

      for (let name of Object.keys(state.groups[groupName].properties)) {
        count++;
        progress = updateProgressIfNecessary(0, total, count, progress, `Validating '${groupName}' properties`, callback);

        if (name !== 'advancedProperties' && name !== 'hostProperties') {
          const property = state.groups[groupName].properties[name];
          if (property.isContainer) {
            const result = validateContainerProperty(state.groups[groupName].label, property.label, property);
            if (result != null) {
              return result;
            }
          } else {
            const result = validateValue(state.groups[groupName].label, property.label, property);
            if (result != null) {
              return result;
            }

          }
        }
      }

      if (state.groups[groupName].properties.advancedProperties !== undefined) {
        for (let name of Object.keys(state.groups[groupName].properties.advancedProperties)) {
          count++;
          progress = updateProgressIfNecessary(0, total, count, progress, `Validating '${groupName}' properties`, callback);

          if (name !== 'advancedProperties') {
            const property = state.groups[groupName].properties.advancedProperties[name];
            if (property.isContainer) {
              const result = validateContainerProperty(state.groups[groupName].label, property.label, property);
              if (result != null) {
                return result;
              }
            } else {
              const result = validateValue(state.groups[groupName].label, property.label, property);
              if (result != null) {
                return result;
              }
            }
          }
        }
      }
    }
  }

  return {
    isValid: true,
    error: ""
  };
};

const processContainerProperty = (property, password) => {
  let inventory = {

  };

  let hasValue = false;

  Object.keys(property.children).forEach(name => {
    let child = property.children[name];

    if (child.isContainer) {
      let result = processContainerProperty(child, password);
      if (result.hasValue) {
        hasValue = true;
      }

      if (result.hasValue) {
        inventory = {
          ...inventory,
          [name]: result.inventory
        }
      }
    } else {

      if (child.currentValue === undefined) return true;
      if (typeof child.currentValue === 'string' && child.currentValue.trim() === '') return true;

      hasValue = true;
      inventory = {
        ...inventory,
        [name]: child.isEncrypted !== undefined && child.isEncrypted && password !== undefined && password.trim() !== '' ? new Encrypted(ansible.encrypt(child.currentValue.trim(), password.trim())) : child.currentValue
      }

    }
    return true;
  });

  return {
    inventory,
    hasValue
  };
};

export const encryptStateIfNecessary = (state, password, callback = undefined) => {
  let result = state;

  const total = totalProperties(state);
  let count = 0;
  let progress = 75;

  if (!!callback) {
    callback({
      progress,
      message: 'Encrypting global properties'
    });
  }

  Object.keys(result.global)
    .filter(name => name !== 'advancedProperties')
    .forEach(name => {
      count++;
      progress = updateProgressIfNecessary(75, total, count, progress, `Encrypting Global State Properties`, callback);

      const property = result.global[name];
      if (property.isContainer) {
        result = {
          ...result,
          global: {
            ...result.global,
            [name]: encryptContainerProperty(property, password)
          }
        }
      } else {
        let value = property.currentValue;
        if (!!property.isEncrypted &&
          !!password && password.trim() !== '' &&
          value !== undefined &&
          value.trim() !== '') {
          value = ansible.encrypt(value.trim(), password);
          result = {
            ...result,
            global: {
              ...result.global,
              [name]: {
                ...result.global[name],
                stateValue: value,
                currentValue: value,
              }
            }
          };
        }
      }
    });

  Object.keys(result.global.advancedProperties)
    .forEach(name => {
      count++;
      progress = updateProgressIfNecessary(75, total, count, progress, `Encrypting Global State Properties`, callback);

      const property = result.global.advancedProperties[name];
      if (property.isContainer) {
        result = {
          ...result,
          global: {
            ...result.global,
            advancedProperties: {
              ...result.global.advancedProperties,
              [name]: encryptContainerProperty(property, password)
            }
          }
        }
      } else {
        let value = property.currentValue;
        if (!!property.isEncrypted &&
          password !== undefined && password.trim() !== '' &&
          value !== undefined &&
          value.trim() !== '') {
          value = ansible.encrypt(value.trim(), password);
          result = {
            ...result,
            global: {
              ...result.global,
              advancedProperties: {
                ...result.global.advancedProperties,
                [name]: {
                  ...result.global.advancedProperties[name],
                  currentValue: value,
                  stateValue: value,
                }
              }
            }
          };
        }
      }
    });

  Object.keys(result.groups)
    .forEach(groupName => {
      Object.keys(result.groups[groupName].properties)
        .filter(name => name !== 'advancedProperties' && name !== 'hostProperties')
        .forEach(name => {
          count++;
          progress = updateProgressIfNecessary(75, total, count, progress, `Encrypting '${groupName}' Group State Properties`, callback);

          const property = result.groups[groupName].properties[name];
          if (property.isContainer) {
            result = {
              ...result,
              groups: {
                ...result.groups,
                [groupName]: {
                  ...result.groups[groupName],
                  properties: {
                    ...result.groups[groupName].properties,
                    [name]: encryptContainerProperty(property, password)
                  }
                }
              }
            }
          } else {
            let value = property.currentValue;
            if (!!property.isEncrypted &&
              password !== undefined && password.trim() !== '' &&
              value !== undefined &&
              value.trim() !== '') {
              value = ansible.encrypt(value.trim(), password);
              result = {
                ...result,
                groups: {
                  ...result.groups,
                  [groupName]: {
                    ...result.groups[groupName],
                    properties: {
                      ...result.groups[groupName].properties,
                      [name]: {
                        ...result.groups[groupName].properties[name],
                        currentValue: value,
                        stateValue: value,
                      },
                    }
                  }
                }
              };
            }
          }
        });

      if (result.groups[groupName].properties.advancedProperties !== undefined) {
        Object.keys(result.groups[groupName].properties.advancedProperties)
          .forEach(name => {
            count++;
            progress = updateProgressIfNecessary(75, total, count, progress, `Encrypting '${groupName}' Group State Properties`, callback);

            const property = result.groups[groupName].properties.advancedProperties[name];
            if (property.isContainer) {
              result = {
                ...result,
                groups: {
                  ...result.groups,
                  [groupName]: {
                    ...result.groups[groupName],
                    properties: {
                      ...result.groups[groupName].properties,
                      advancedProperties: {
                        ...result.groups[groupName].properties.advancedProperties,
                        [name]: encryptContainerProperty(property, password)
                      }
                    }
                  }
                }
              }
            } else {
              let value = property.currentValue;
              if (!!property.isEncrypted &&
                password !== undefined && password.trim() !== '' &&
                value !== undefined &&
                value !== '') {
                value = ansible.encrypt(value.trim(), password);
                result = {
                  ...result,
                  groups: {
                    ...result.groups,
                    [groupName]: {
                      ...result.groups[groupName],
                      properties: {
                        ...result.groups[groupName].properties,
                        advancedProperties: {
                          ...result.groups[groupName].properties.advancedProperties,
                          [name]: {
                            ...result.groups[groupName].properties[name],
                            currentValue: value,
                            stateValue: value,
                          },
                        }
                      }
                    }
                  }
                };
              }
            }
          });
      }
    });

  return result;
};

export const reconcileStateProperties = (state, callback = undefined) => {
  let result = state;
  const total = totalProperties(result);
  let count = 0;
  let progress = 50;

  if (!!callback) {
    callback({
      progress: 25,
      message: 'Reconciling Global State Properties'
    });
  }

  Object.keys(result.global)
    .filter(name => name !== 'advancedProperties')
    .forEach(name => {
      count++;
      progress = updateProgressIfNecessary(50, total, count, progress, 'Reconciling Global State Properties', callback);

      const property = result.global[name];
      if (property.isContainer) {
        result = {
          ...result,
          global: {
            ...result.global,
            [name]: reconcileContainerProperty(property)
          }
        }
      } else {
        result = {
          ...result,
          global: {
            ...result.global,
            [name]: {
              ...result.global[name],
              stateValue: property.currentValue,
              isValueModified: false
            }
          }
        };

        if (property.modelDefaultValue !== undefined) {
          result = {
            ...result,
            global: {
              ...result.global,
              [name]: {
                ...result.global[name],
                currentDefaultValue: property.modelDefaultValue,
                isDefaultModified: false
              }
            }
          };
        }
      }
    });

  Object.keys(result.global.advancedProperties)
    .forEach(name => {
      count++;
      progress = updateProgressIfNecessary(50, total, count, progress, 'Reconciling Global State Properties', callback);

      const property = result.global.advancedProperties[name];
      if (property.isContainer) {
        result = {
          ...result,
          global: {
            ...result.global,
            advancedProperties: {
              ...result.global.advancedProperties,
              [name]: reconcileContainerProperty(property)
            }
          }
        }
      } else {
        result = {
          ...result,
          global: {
            ...result.global,
            advancedProperties: {
              ...result.global.advancedProperties,
              [name]: {
                ...result.global.advancedProperties[name],
                stateValue: property.currentValue,
                isValueModified: false
              }
            }
          }
        };

        if (property.modelDefaultValue !== undefined) {
          result = {
            ...result,
            global: {
              ...result.global,
              advancedProperties: {
                ...result.global.advancedProperties,
                [name]: {
                  ...result.global.advancedProperties[name],
                  currentDefaultValue: result.global.advancedProperties[name].modelDefaultValue,
                  isDefaultModified: false
                }
              }
            }
          };
        }
      }
    });

  Object.keys(result.groups)
    .forEach(groupName => {
      Object.keys(result.groups[groupName].properties)
        .filter(name => name !== 'advancedProperties' && name !== 'hostProperties')
        .forEach(name => {
          count++;
          progress = updateProgressIfNecessary(50, total, count, progress, `Reconciling '${groupName}' Group State Properties`, callback);

          const property = result.groups[groupName].properties[name];
          if (property.isContainer) {
            result = {
              ...result,
              groups: {
                ...result.groups,
                [groupName]: {
                  ...result.groups[groupName],
                  properties: {
                    ...result.groups[groupName].properties,
                    [name]: reconcileContainerProperty(property)
                  }
                }
              }
            }
          } else {
            result = {
              ...result,
              groups: {
                ...result.groups,
                [groupName]: {
                  ...result.groups[groupName],
                  properties: {
                    ...result.groups[groupName].properties,
                    [name]: {
                      ...result.groups[groupName].properties[name],
                      stateValue: property.currentValue,
                      isValueModified: false
                    },
                  }
                }
              }
            };

            if (property.modelDefaultValue !== undefined) {
              result = {
                ...result,
                groups: {
                  ...result.groups,
                  [groupName]: {
                    ...result.groups[groupName],
                    properties: {
                      ...result.groups[groupName].properties,
                      [name]: {
                        ...result.groups[groupName].properties[name],
                        currentDefaultValue: property.modelDefaultValue,
                        isDefaultModified: false
                      }
                    }
                  }
                }
              };
            }
          }
        });

      if (result.groups[groupName].properties.advancedProperties !== undefined) {
        Object.keys(result.groups[groupName].properties.advancedProperties)
          .forEach(name => {
            count++;
            progress = updateProgressIfNecessary(50, total, count, progress, `Reconciling '${groupName}' Group State Properties`, callback);

            const property = result.groups[groupName].properties.advancedProperties[name];
            if (property.isContainer) {
              result = {
                ...result,
                groups: {
                  ...result.groups,
                  [groupName]: {
                    ...result.groups[groupName],
                    properties: {
                      ...result.groups[groupName].properties,
                      advancedProperties: {
                        ...result.groups[groupName].properties.advancedProperties,
                        [name]: reconcileContainerProperty(property)
                      }
                    }
                  }
                }
              }
            } else {
              result = {
                ...result,
                groups: {
                  ...result.groups,
                  [groupName]: {
                    ...result.groups[groupName],
                    properties: {
                      ...result.groups[groupName].properties,
                      advancedProperties: {
                        ...result.groups[groupName].properties.advancedProperties,
                        [name]: {
                          ...result.groups[groupName].properties.advancedProperties[name],
                          stateValue: property.currentValue,
                          isValueModified: false
                        }
                      }
                    }
                  }
                }
              };

              if (property.modelDefaultValue !== undefined) {
                result = {
                  ...result,
                  groups: {
                    ...result.groups,
                    [groupName]: {
                      ...result.groups[groupName],
                      properties: {
                        ...result.groups[groupName].properties,
                        advancedProperties: {
                          ...result.groups[groupName].properties.advancedProperties,
                          [name]: {
                            ...result.groups[groupName].properties.advancedProperties[name],
                            currentDefaultValue: property.modelDefaultValue,
                            isDefaultModified: false
                          }
                        }
                      }
                    }
                  }
                };
              }
            }
          });
      }

      if (result.groups[groupName].properties.hostProperties !== undefined) {
        Object.keys(result.groups[groupName].properties.hostProperties)
          .forEach(name => {
            count++;
            progress = updateProgressIfNecessary(50, total, count, progress, `Reconciling '${groupName}' Group State Properties`, callback);

            const property = result.groups[groupName].properties.hostProperties[name];
            if (property.isContainer) {
              result = {
                ...result,
                groups: {
                  ...result.groups,
                  [groupName]: {
                    ...result.groups[groupName],
                    properties: {
                      ...result.groups[groupName].properties,
                      hostProperties: {
                        ...result.groups[groupName].properties.hostProperties,
                        [name]: reconcileContainerProperty(property)
                      }
                    }
                  }
                }
              }
            } else {
              result = {
                ...result,
                groups: {
                  ...result.groups,
                  [groupName]: {
                    ...result.groups[groupName],
                    properties: {
                      ...result.groups[groupName].properties,
                      hostProperties: {
                        ...result.groups[groupName].properties.hostProperties,
                        [name]: {
                          ...result.groups[groupName].properties.hostProperties[name],
                          stateValue: property.currentValue,
                          isValueModified: false
                        }
                      }
                    }
                  }
                }
              };

              if (property.modelDefaultValue !== undefined) {
                result = {
                  ...result,
                  groups: {
                    ...result.groups,
                    [groupName]: {
                      ...result.groups[groupName],
                      properties: {
                        ...result.groups[groupName].properties,
                        hostProperties: {
                          ...result.groups[groupName].properties.hostProperties,
                          [name]: {
                            ...result.groups[groupName].properties.hostProperties[name],
                            currentDefaultValue: property.modelDefaultValue,
                            isDefaultModified: false
                          }
                        }
                      }
                    }
                  }
                };
              }
            }
          });
      }
    });

    

  return result;
};

const reconcileContainerProperty = (root) => {
  let result = root;
  Object.keys(result.children)
    .forEach(name => {
      const property = result.children[name];
      if (property.isContainer) {
        result = {
          ...result,
          children: {
            ...result.children,
            [name]: reconcileContainerProperty(property)
          }
        };
      } else {
        result = {
          ...result,
          children: {
            ...result.children,
            [name]: {
              ...result.children[name],
              stateValue: result.children[name].currentValue,
              isValueModified: false
            }
          }
        };

        if (result.children[name].modelDefaultValue !== undefined) {
          result = {
            ...result,
            children: {
              ...result.children,
              [name]: {
                ...result.children[name],
                currentDefaultValue: result.children[name].modelDefaultValue,
                isDefaultModified: false
              }
            }
          };
        }
      }
    });
  return result;
};

export const encryptContainerProperty = (root, password) => {
  let result = root;
  Object.keys(result.children)
    .forEach(name => {
      const property = result.children[name];
      if (property.isContainer) {
        result = {
          ...result,
          children: {
            ...result.children,
            [property]: encryptContainerProperty(property, password)
          }
        };
      } else {
        let value = property.currentValue;
        if (!!property.isEncrypted &&
          !!password && password.trim() !== '' &&
          !!value &&
          value !== '') {
          value = ansible.encrypt(value, password);
          result = {
            ...result,
            children: {
              ...result.children,
              [name]: {
                ...result.children[name],
                currentValue: value,
                stateValue: value,
              }
            }
          };
        }
      }
    });

  return result;
};

const validateContainerProperty = (groupName, parentName, root) => {
  let result = null;
  if (root.children !== undefined) {
    for (let name of Object.keys(root.children)) {
      const property = root.children[name];
      if (property.isContainer) {
        result = validateContainerProperty(groupName, parentName + ' > ' + property.label, property);
        if (result !== null) {
          return result;
        }
      } else {
        const result = validateValue(groupName, parentName + ' > ' + property.label, property);
        if (result != null) {
          return result;
        }
      }
    }

  }
  return null;
};

const validateValue = (groupName, name, property) => {
  let result = null;

  if ((property.isOptional !== undefined && !property.isOptional) && (property.currentValue === undefined || property.currentValue === '')) {
    return {
      isValid: false,
      error: 'Value of the "' + (groupName === 'Global' ? groupName + '" property "' + name : groupName + '" property "' + name) + '" is mandatory.'
    };
  }
  if (property.min !== undefined && property.min > 0 && property.currentValue !== undefined && property.currentValue !== '') {
    if (property.type === 'number') {
      if (parseFloat(property.currentValue) < property.min) {
        return {
          isValid: false,
          error: 'Value of the "' + (groupName === 'Global' ? groupName + '" property "' + name : groupName + '" property "' + name) + '" must be greater than ' + property.min + '.'
        };
      }
    } else {
      if (String(property.currentValue).length < property.min) {
        return {
          isValid: false,
          error: 'Length of the "' + (groupName === 'Global' ? groupName + '" property "' + name : groupName + '" property "' + name) + '" is less than the minimum length ' + property.min + '.'
        };
      }
    }
  }
  if (property.max !== undefined && property.max > 0 && property.currentValue !== undefined && property.currentValue !== '') {
    if (property.type === 'number') {
      if (parseFloat(property.currentValue) > property.max) {
        return {
          isValid: false,
          error: 'Value of the "' + (groupName === 'Global' ? groupName + '" property "' + name : groupName + '" property "' + name) + '" must be less than ' + property.max + '.'
        };
      }
    } else {
      if (String(property.currentValue).length > property.max) {
        return {
          isValid: false,
          error: 'Length of the "' + (groupName === 'Global' ? groupName + '" property "' + name : groupName + '" property "' + name) + '" is greather than the maximum length ' + property.max + '.'
        };
      }
    }
  }
  if (property.pattern !== undefined && property.currentValue !== undefined && property.currentValue !== '') {
    var regexp = property.pattern.match(/^\/(.*?)\/([gim]*)$/) ? new RegExp(property.pattern.match(/^\/(.*?)\/([gim]*)$/)[1], property.pattern.match(/^\/(.*?)\/([gim]*)$/)[2]) : new RegExp(property.pattern);
    if (!property.currentValue.match(regexp)) {
      return {
        isValid: false,
        error: 'The "' + (groupName === 'Global' ? groupName + '" property "' + name : groupName + '" property "' + name) + '" is invalid. ' + property.invalidPatternText + '.'
      };
    }
  }

  return null;
};

const Allowed = {
  Uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
  Lowers: "qwertyuiopasdfghjklzxcvbnm",
  Numbers: "1234567890",
  Symbols: "+=_!@#$%^*"
}

const getRandomCharFromString = (str) => str.charAt(Math.floor(Math.random() * str.length));

export const generatePassword = (patternRegex, minLength, maxLength) => {
  if (!(patternRegex instanceof RegExp)) {
    throw new Error('Invalid pattern. The pattern should be a valid regular expression.');
  }

  if (minLength <= 0 || maxLength <= 0 || minLength > maxLength) {
    throw new Error('Invalid length. The minimum and maximum length should be positive numbers, and the minimum length should be less than or equal to the maximum length.');
  }

  const passwordLength = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  let password = '';

  while (password.length == 0 || !patternRegex.test(password)) {
    password = '';
    while (password.length < passwordLength) {
      const randomChar = getRandomCharFromString(Object.values(Allowed).join(''));
      password += randomChar;
    }
  }

  return password;
};
