import React from 'react';
import { Card, Tab } from 'react-bootstrap';
import PropertiesListItem from './PropertiesListItem/PropertiesListItem';
import AdvancedPropertiesList from './AdvancedPropertiesList/AdvancedPropertiesList';

const { Header, Body } = Card;

const propertiesList = (props) => {
    const items = [];
    const listHasProperties = props.properties !== undefined && props.properties.constructor === Object && Object.entries(props.properties).length > 0;

    if (listHasProperties) {
        items.push(Object.keys(props.properties)
            .filter(name => name !== 'advancedProperties' && name !== 'hostProperties' && (props.properties[name].isHidden === undefined || !props.properties[name].isHidden))
            .map((name, index) => (
                <PropertiesListItem
                    key={props.group + '_' + index}
                    group={props.group}
                    name={name}
                    advanced={false}
                    property={props.properties[name]}
                    hostIndex={props.hostIndex}
                />
            ))
        );
    }

    const card = listHasProperties ? props.hostIndex === undefined ?(
        <Card>
            <Header as="h6">Properties</Header>
            <Body>
                {items}
                <AdvancedPropertiesList
                    group={props.group}
                    properties={props.properties.advancedProperties}
                />
            </Body>
        </Card>

    ) : (<div>{items}</div>) : null;

    return card;
};

export default propertiesList;
