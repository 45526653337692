import React from 'react';
import Feedback from "../Feedback/Feedback";

const FileControl = (props) => (
    <div className="custom-file mb-1">
        <input type="file" id="file" disabled={!!props.disabled} className={props.controlClass} accept={props.accepts} onChange={props.onChange}/>
        <label className="custom-file-label" htmlFor="file">{props.fileName}</label>
        <Feedback text={props.feedback}/>
    </div>
);

export default FileControl;
