import React, { useContext, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { controlClass } from '../../../utility/ui-util';
import ControlLabel from "../ControlLabel/ControlLabel";
import StateContext from '../../../contexts/state-context';
import '../../../styles/VaultPassword.css';
import ReactMarkdown from 'react-markdown/with-html';
import {
    generatePassword
} from '../../../processor/state-processor';

const TextInput = (props) => {
    const [type, setType] = useState(!!props.variable.isEncrypted ? 'password' : (props.variable.type ? props.variable.type : 'text'));
    const [buttonClass, setButtonClass] = useState('fa fa-eye');
    const useDefault = (props.variable.useDefault === undefined) ? true : false;
    const [validated, setValidated] = useState(false);
    const [autogenerate, setAutogenerate] = useState(true);
    const regexp = props.variable.pattern ? (props.variable.pattern.match(/^\/(.*?)\/([gim]*)$/) ? new RegExp(props.variable.pattern.match(/^\/(.*?)\/([gim]*)$/)[1], props.variable.pattern.match(/^\/(.*?)\/([gim]*)$/)[2]) : new RegExp(props.variable.pattern)) : null;
    const min = props.variable.min ? props.variable.min : -1;
    const max = props.variable.max ? props.variable.max : -1;
    const button = props.variable.isEncrypted !== undefined && props.variable.isEncrypted ? (
        <button className='unmask'
            title='Mask/Unmask password to check content'
            onClick={e => {
                if (type === 'password') {
                    setButtonClass('fa fa-eye-slash');
                    setType('text');
                } else {
                    setButtonClass('fa fa-eye');
                    setType('password');
                }
            }}>
            <i className={buttonClass}
                aria-hidden='true'></i>
        </button>
    ) : null;

    const validateValue = (value) => {
        const allErrors = {};
        if (!useDefault && value === props.variable.currentDefaultValue) {
            value = '';
        }
        if (min > 0) {
            if (type === 'number') {
                if (parseFloat(value) < min) {
                    allErrors.invalid = "Value must be greater than " + min + "."
                }
            } else {
                if (String(value).length < min) {
                    allErrors.invalid = "Length is shorter than the minimum length " + min + "."
                }
            }
        }
        if (Object.keys(allErrors).length == 0) {
            if (max > 0) {
                if (type === 'number') {
                    if (parseFloat(value) > max) {
                        allErrors.invalid = "Value must be less than " + max + "."
                    }
                } else {
                    if (String(value).length > max) {
                        allErrors.invalid = "Length is longer than the maximum length " + max + "."
                    }
                }
            }
        }
        if (Object.keys(allErrors).length == 0) {
            if (regexp) {
                if (!value.match(regexp)) {
                    allErrors.invalid = props.variable.invalidPatternText;

                }
            }
        }
        return allErrors;
    };

    const [errors, setErrors] = useState((props.variable.currentValue !== '') ? validateValue(props.variable.currentValue) : {});

    const handleChange = async (value, setPropertyValue) => {
        let allErrors = validateValue(value);
        setErrors(allErrors);
        if (Object.keys(allErrors).length > 0) {
            setValidated(false);
        }
        else {
            setValidated(true);
        }
        if(value === ''){
            setAutogenerate(false);
        }
        setPropertyValue(props.group, props.advanced, props.variableName, value, props.hostIndex);
    };

    if (autogenerate && props.variable.isAuto && props.variable.modelDefaultValue === 'auto' && !props.variable.isOptional && !props.variable.isReadOnly && !props.variable.forceDefault && props.variable.currentValue === '') {
        useContext(StateContext).setPropertyValue(props.group, props.advanced, props.variableName, generatePassword(regexp ? regexp : /.*/, min > 0 ? min : 6, max > 0 ? max : 12), props.hostIndex);
    }

    return (
        <Form.Group>
            <ControlLabel isOptional={props.variable.isOptional} isObsolete={!!props.variable.isObsolete} isReadOnly={props.variable.isReadOnly} text={props.variable.label}></ControlLabel>
            <div className='formWithButton'>
                <StateContext.Consumer>
                    {({ setPropertyValue }) => <Form.Control placeholder={props.variable.label}
                        className={controlClass(props.variable, props.variable.currentValue)}
                        type={type}
                        value={props.variable.currentValue}
                        onChange={e => handleChange(e.target.value, setPropertyValue)}
                        readOnly={props.variable.isReadOnly}
                        plaintext={props.variable.isReadOnly}
                        isInvalid={!!errors.invalid}
                    />}
                </StateContext.Consumer>
                {props.variable.isAuto && (
                    <StateContext.Consumer>
                        {({ setPropertyValue }) => <button className='auto'
                            title='Auto-generate new password'
                            onClick={e => handleChange(generatePassword(regexp ? regexp : /.*/, min > 0 ? min : 6, max > 0 ? max : 12), setPropertyValue)}>
                            <i className='fas fa-magic'
                                aria-hidden='true'></i>
                        </button>}
                    </StateContext.Consumer>
                )}
                {button}
                {errors.invalid && (
                    <Form.Control.Feedback type="invalid">
                        {errors.invalid}
                    </Form.Control.Feedback>
                )}
            </div>
            {props.variable.description && (
                <Form.Text className="text-muted">
                    <ReactMarkdown
                        source={props.variable.description + (props.variable.isAuto ? ' *(' + (props.variable.modelDefaultValue === 'auto' ? 'Auto-generated - ' : '') + 'Use the Auto-Generation button to ' + (props.variable.modelDefaultValue === 'auto' ? 're' : '') + 'generate or alternatively choose your own)*' : '')}
                        escapeHtml={false} />
                </Form.Text>
            )}
            {props.variable.isDefaultModified && (
                <Form.Text className="text-danger">
                    {'** This value has been modified from it\'s original value of \'' + props.variable.stateValue + '\''}
                </Form.Text>
            )}
        </Form.Group>
    )
};

export default TextInput;
