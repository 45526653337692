import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import NavigationItem from './NavigationItem/NavigationItem';

const navigationPanel = (props) => {
    let items = [];

    if (Object.entries(props.state).length > 0 && props.state.constructor === Object) {
        items.push(<NavigationItem key='global' name='global' label='Global' onGroupSelect={props.onGroupSelect}/>);
        items.push(<NavigationItem key='hosts' name='hosts' label='Hosts' onGroupSelect={props.onGroupSelect}/>);
        items.push(Object.keys(props.state.groups)
            .map(group => group.trim())
            .map(group => <NavigationItem key={group} name={group} label={props.state.groups[group].label} onGroupSelect={props.onGroupSelect}/>));
    }

    return (
        <Col sm={3}>
            <Nav variant="pills" className="flex-column" defaultActiveKey="global">
                {items}
            </Nav>
        </Col>
    );
};

export default navigationPanel;
