import React from 'react';
import StateContext from '../../../../../../../contexts/state-context';
import CreatableSelect from 'react-select/creatable';

const arrayPropertyItem = (props) => {
    return (
        <StateContext.Consumer>
            {({ setArrayElementValue, deleteArrayElement }) => <div className='formWithButton host-input'>
                {(props.options !== undefined && props.options.length > 0) ? <CreatableSelect
                    isClearable={false}
                    classNames={{
                        control: (state) => 'form-control custom-select'
                    }}
                    onChange={(newValue) => { setArrayElementValue(props.group, props.advanced, props.name, props.index, newValue.value) }}
                    onCreateOption={(newValue) => { setArrayElementValue(props.group, props.advanced, props.name, props.index, newValue) }}
                    getOptionLabel={(option) => (option.label === undefined) ? option.value : option.label}
                    getOptionValue={(option) => option.value}
                    options={props.options.filter(p => !props.elements.includes(p.value))}
                    value={{ label: (props.options.find(o => o.value === props.property) === undefined || (props.options.find(o => o.value === props.property) !== undefined && props.options.find(o => o.value === props.property).label === undefined)) ? props.property : props.options.find(o => o.value === props.property).label, value: props.property }}
                /> : <input
                    placeholder='New Value'
                    type='text'
                    className={'form-control custom-input'}
                    value={props.property || ''}
                    onChange={e => { setArrayElementValue(props.group, props.advanced, props.name, props.index, e.target.value) }}
                />}
                <button className='unmask'
                    title={`Delete '${props.property !== undefined ? props.property : 'empty'}' entry`}
                    onClick={e => {
                        deleteArrayElement(props.group, props.advanced, props.name, props.index);
                    }}
                >
                    <i className='far far fa-trash-alt text-danger' aria-hidden='true'></i>
                </button>
            </div>}
        </StateContext.Consumer>
    );
}

export default arrayPropertyItem;
