import React from 'react';
import PropertiesListItem from '../PropertiesListItem';
import { Card } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown/with-html';
import Preview from '../../../../../UI/Branding/Preview';

const { Header, Body } = Card;

const containerProperty = (props) => {
    const items = [];

    items.push(Object.keys(props.property.children).map((name, index) => {
        return <PropertiesListItem
            key={index}
            name={props.name + '.' + name}
            group={props.group}
            property={props.property.children[name]}
            advanced={props.advanced}
        />;
    }));

    return (
        <Card>
            <Header>
                <Card.Title as="h6">{props.property.label}</Card.Title>
                {props.property.description && <Card.Subtitle as="small" className="text-muted">
                    <ReactMarkdown
                        source={props.property.description}
                        escapeHtml={false}/>
                </Card.Subtitle>}
            </Header>
            <Body>
                {items}
                {(props.name === 'global_branding') ?
                    <Card>
                        <Header>
                            <Card.Title as="h6">AgentView Branding Preview</Card.Title>
                            <Card.Subtitle as="small" className="text-muted">The below images and colours are a preview of the branding that will be generated for AgentView based on the theme colours and images selected. These values can be overridden if necessary in the AgentView tab.</Card.Subtitle>
                        </Header>
                        <Body>
                            <Preview />  
                        </Body>  
                    </Card> : null
                }
            </Body>
        </Card>
    )
};

export default containerProperty;
