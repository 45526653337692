import React from 'react';
import ArrayPropertyItem from './ArrayPropertyItem/ArrayPropertyItem';
import { Form } from 'react-bootstrap';
import StateContext from '../../../../../../contexts/state-context';
import ReactMarkdown from 'react-markdown/with-html';

const { Group, Label, Text } = Form;

const arrayProperty = (props) => {
    const options = props.property.values ? props.property.values : undefined;
    const items = [];
    items.push(Object.keys(props.property.elements)
        .map((name, index) => {
            return <ArrayPropertyItem
                key={props.group + '_' + props.name + '_' + index}
                name={props.name}
                property={props.property.elements[index]}
                index={index}
                group={props.group}
                advenced={props.advanced}
                options={options}
                elements={props.property.elements}
            />
        })
    );

    return (
        <Group>
            <StateContext.Consumer>
                {({ addArrayElement }) => <Label className='control-label'>{props.property.label} <button type='button' title='Add a new element to the array' className='array-add-button text-success' onClick={() => addArrayElement(props.group, props.advanced, props.name)}><i
                    className="fas fa-plus-circle fa-lg" /></button></Label>}
            </StateContext.Consumer>
            {items}
            <Text className="text-muted">
                <ReactMarkdown
                    source={props.property.description}
                    escapeHtml={false} />
            </Text>
        </Group>
    )
};

export default arrayProperty;
