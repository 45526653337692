import React from 'react';
import { Form, Alert } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown/with-html';

const Select = (props) => (
            <Alert variant='info'>
                <Alert.Heading as="div" className='form-label control-label'>{props.variable.label}</Alert.Heading>
                <Form.Text className="text-muted">
                    <ReactMarkdown
                        source={props.variable.description}
                        escapeHtml={false} />
                </Form.Text>
            </Alert>
    );

export default Select;
