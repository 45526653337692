import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import 'core-js/stable';
import "regenerator-runtime/runtime";

const jsx = (
        <App/>
);
ReactDOM.render(jsx, document.getElementById('root'));

