import React from 'react';
import { Form } from 'react-bootstrap';
import { controlClass } from '../../../utility/ui-util';
import ControlLabel from '../ControlLabel/ControlLabel';
import StateContext from '../../../contexts/state-context';
import ReactMarkdown from 'react-markdown/with-html';
const Checkbox = (props) => (
    <Form.Group>
        <Form.Check type='checkbox'>
            <StateContext.Consumer>
                {({setPropertyValue}) => <Form.Check.Input type='checkbox' onChange={e => {
                    setPropertyValue(props.group, props.advanced, props.variableName, e.target.checked, props.hostIndex);
                }} checked={props.variable.currentValue} className={controlClass(props.variable, props.variable.currentValue, 'cb-')}/>}
            </StateContext.Consumer>
            <ControlLabel isOptional={props.variable.isOptional} isObsolete={!!props.variable.isObsolete} text={props.variable.label}/>
        </Form.Check>
        <Form.Text className='text-muted'>
            <ReactMarkdown
              source={props.variable.description}
              escapeHtml={false}/>
        </Form.Text>
    </Form.Group>
);

export default Checkbox;
