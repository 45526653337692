import {Schema, Type} from "js-yaml";

export function Encrypted(data) {
    this.klass = 'Encrypted';
    this.data = data;
}

const vaultType = new Type('!vault', {
    kind: 'scalar',
    construct: function (data) {
        return new Encrypted(data);
    },
    instanceOf: Encrypted,
    resolve: function(data) {
        return data !== null && data.startsWith('$ANSIBLE_VAULT;1.1;AES256');
    },
    represent: function(data) {
        return data.data;
    }
});
export default Schema.create([ vaultType ]);
