import React from 'react';
import { Card } from 'react-bootstrap';
import HostsListItem from './HostsListItem/HostsListItem';
import StateContext from '../../../../contexts/state-context';

const { Header, Body } = Card;

const hostsList = (props) => {
    const items = [];

    items.push(props.hosts.map((hostname, index) => (
        <HostsListItem
            key={props.group + '_host_' + index}
            index={index}
            group={props.group}
            hostname={hostname.name}
        />
    )));

    return (
        <Card className='hosts-area'>
            <Header as="h6">Hosts </Header>
            <Body>
                {items}
            </Body>
        </Card>
    )
};

export default hostsList;
