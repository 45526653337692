import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import { controlClass } from '../../../utility/ui-util';
import { Form } from 'react-bootstrap';
import ControlLabel from "../ControlLabel/ControlLabel";
import StateContext from '../../../contexts/state-context';
import ReactMarkdown from 'react-markdown/with-html';

const ColorPicker = (props) => {
    const [showPicker, setShowPicker] = useState(false);
    const useDefault = (props.variable.useDefault === undefined) ? true : false;
    const displayValue = (props.variable.currentValue === '') ? props.variable.currentDefaultValue : props.variable.currentValue;
    const styles = reactCSS({
        'default': {
            color: {
                width: '40px',
                height: '15px',
                borderRadius: '3px',
                background: displayValue,
            },
            popover: {
                position: 'absolute',
                zIndex: '3',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
            swatch: {
                padding: '6px',
                background: '#ffffff',
                borderRadius: '2px',
                cursor: 'pointer',
                display: 'inline-block',
                boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
                position: 'absolute',
                right: '10px',
                top: '6px',
                border: 'none',
                backgroundColor: 'Transparent',
                backgroundRepeat: 'no-repeat',
                borderRadius: '3px',
                outline: 'none',
                textAlign: 'center',
                fontWeight: 'bold',
            },
        },
    });
    const picker = showPicker !== undefined && showPicker ? (
        <StateContext.Consumer>
            {({ setPropertyValue }) =>
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={e => {
                        setShowPicker(!showPicker);
                    }} />
                    <SketchPicker color={displayValue} 
                    onChange={color => {
                        let value = color.hex;
                        if(!useDefault && value === props.variable.currentDefaultValue){
                            value = '';
                        }
                        setPropertyValue(props.group, props.advanced, props.variableName, value, props.hostIndex);
                    }} />
                </div>}
        </StateContext.Consumer>
    ) : null;
    const button = (
        <div style={styles.swatch} onClick={e => {
            setShowPicker(!showPicker);
        }}>
            <div style={styles.color} />
        </div>
    );
    const clearButton = (props.variable.currentValue !== '' && props.variable.currentValue !== props.variable.currentDefaultValue) ? (
        <StateContext.Consumer>
            {({ setPropertyValue }) =>
                <button className='clear colour'
                    title='Click to clear colour'
                    onClick={e => {
                        setPropertyValue(props.group, props.advanced, props.variableName, '', props.hostIndex);
                    }}>
                    <i className='fa fa-times' aria-hidden='true'></i>
                </button>
            }
        </StateContext.Consumer>
    ) : null;

    return (
        <Form.Group>
            <ControlLabel isOptional={props.variable.isOptional} isObsolete={!!props.variable.isObsolete} isReadOnly={props.variable.isReadOnly} text={props.variable.label}></ControlLabel>
            <div className="formWithButton">
                <Form.Control placeholder={props.variable.label}
                    className={controlClass(props.variable, (props.variable.isReadOnly && props.variable.forceDefault) ? props.variable.currentDefaultValue : props.variable.currentValue)}
                    type='textinput'
                    value={displayValue}
                    readOnly={true}
                    plaintext={true}
                    onClick={e => {
                        setShowPicker(!showPicker);
                    }}
                />
                {clearButton}
                {button}
                {picker}
            </div>
            <Form.Text className="text-muted">
                <ReactMarkdown
                    source={props.variable.description}
                    escapeHtml={false} />
            </Form.Text>
        </Form.Group>
    )
};

export default ColorPicker;
