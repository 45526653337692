
export const imageTemplates = {
    splashBackgroundTemplate: "<div xmlns='http://www.w3.org/1999/xhtml' style='background-color: #fff; width: 100%; height: 100%; border-style: solid; border-color: {0}; border-width: 1px; box-sizing: border-box;'><div style='width: 100%; height: 240px; background-color: {0}; position: absolute; top: 0;'><img style='height: 76px; position: absolute; top:50%; left:50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);' src='data:image/png;base64,{1}' /></div><div style='width: 100%; height: 4px; background-color: {2}; position: absolute; top: 240px;'></div></div>",
    popupBackgroundTemplate: "<div xmlns='http://www.w3.org/1999/xhtml' style='width: 100%; height: 100%; position: relative; background-color: #fff;'>" +
        "<div style='width: 100%; height: 10px; background-color: {1}; position: absolute; top: 0;'></div>" +
        "<div style='width: 100%; height: 45px; background-color: {0}; position: absolute; top: 10px;'></div>" +
        "</div>",
    onePanelBackgroundTemplate: "<div xmlns='http://www.w3.org/1999/xhtml' style='width: 100%; height: 100%; position: relative; background-color: #fff;'>" +
        "<div style='width: 100%; height: 40px; background-color: {0}; position: absolute; top: 0;'></div>" +
        "<img style='width: 100px; height: 40px; position: absolute; top: 10px; left: 0; padding-left: 10px; padding-bottom: 10px; border-style: solid; background-color: {0}; border-color: {0}; border-width: 0 10px 0 0; border-bottom-left-radius: 10px;' src='data:image/png;base64,{1}' />" +
        "</div>",
    mainBackgroundTemplate: "<div xmlns='http://www.w3.org/1999/xhtml' style='width: 100%; height: 100%; position: relative; background-color: #fff;'>" +
        "<div style='width: 100%; height: 40px; background-color: {0}; position: absolute; top: 0;'></div>" +
        "<img style='width: 100px; height: 40px; position: absolute; top: 10px; left: 0; padding-left: 10px; padding-bottom: 10px; border-style: solid; background-color: {0}; border-color: {0}; border-width: 0 10px 0 0; border-bottom-left-radius: 10px;' src='data:image/png;base64,{1}' />" +
        "<div style='width: 100%; height: 22px; background-color: {2}; position: absolute; top: 295px;'></div>" +
        "<div style='width: 100%; height: 1px; background-color: {3}; position: absolute; top: 318px;'></div>" +
        "<div style='width: 100%; height: 22px; background-color: color-mix(in srgb, {2}, white 90%); position: absolute; top: 319px;'></div>" +
        "</div>",
    exitBackgroundTemplate: "<div xmlns='http://www.w3.org/1999/xhtml' style='width: 100%; height: 100%; position: relative; background-color: #fff;'>" +
        "<div style='width: 100%; height: 25px; background-color: {0}; position: absolute; top: 0;'></div>" +
        "<div style='width: 100%; height: 4px; background-color: {1}; position: absolute; top: 26px;'></div>" +
        "<div style='width: 100%; height: 63px; background-color: color-mix(in srgb, {1}, white 90%); position: absolute; top: 91px;'></div>" +
        "</div>",
    enabledButtonTemplate: "<div xmlns='http://www.w3.org/1999/xhtml' style='box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; width: 100%; height: 100%; background-color: {0}; position: absolute; top: 0; border: 3px solid {0}; border-radius: 3px;'></div>",
    disabledButtonTemplate: "<div xmlns='http://www.w3.org/1999/xhtml' style='box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; width: 100%; height: 100%; background-color: color-mix(in srgb, {0}, white 50%); position: absolute; top: 0; border: 3px solid color-mix(in srgb, {0}, white 50%); border-radius: 3px;'></div>",
    activeButtonTemplate: "<div xmlns='http://www.w3.org/1999/xhtml' style='box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; width: 100%; height: 100%; background-color: color-mix(in srgb, {0}, black 10%); position: absolute; top: 0; border: 3px solid color-mix(in srgb, {0}, black 10%); border-radius: 3px;'></div>"
};