import React from 'react';
import { Nav } from 'react-bootstrap';

const navigationItem = (props) => (
    <Nav.Item>
        <Nav.Link eventKey={props.name} onSelect={() => props.onGroupSelect(props.name)}>{props.label}</Nav.Link>
    </Nav.Item>
);

export default navigationItem;
