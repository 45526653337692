import React from 'react';
import Checkbox from '../../../../../UI/Checkbox/Checkbox';
import LOV from '../../../../../UI/LOV/LOV';
import TextInput from '../../../../../UI/TextInput/TextInput';
import ColorPicker from '../../../../../UI/ColorPicker/ColorPicker';
import ImagePicker from '../../../../../UI/ImagePicker/ImagePicker';
import FontFamily from '../../../../../UI/Font/FontFamily';
import Font from '../../../../../UI/Font/Font';
import Info from '../../../../../UI/Info/Info';


const property = (props) => {
    let renderedProperty = null;
    if (props.property.control_type === 'checkbox') {
        renderedProperty = <Checkbox variableName={props.name} variable={props.property} group={props.group} advanced={props.advanced} hostIndex={props.hostIndex} />
    } else if (props.property.control_type === 'lov') {
        renderedProperty = <LOV variableName={props.name} variable={props.property} group={props.group} advanced={props.advanced} hostIndex={props.hostIndex} />
    } else if (props.property.control_type === 'color') {
        renderedProperty = <ColorPicker variableName={props.name} variable={props.property} group={props.group} advanced={props.advanced} hostIndex={props.hostIndex} />
    } else if (props.property.control_type === 'image') {
        renderedProperty = <ImagePicker variableName={props.name} variable={props.property} group={props.group} advanced={props.advanced} hostIndex={props.hostIndex} />
    } else if (props.property.control_type === 'fontFamily') {
        renderedProperty = <FontFamily variableName={props.name} variable={props.property} group={props.group} advanced={props.advanced} hostIndex={props.hostIndex} />
    } else if (props.property.control_type === 'font') {
        renderedProperty = <Font variableName={props.name} variable={props.property} group={props.group} advanced={props.advanced} hostIndex={props.hostIndex} />
    } else if (props.property.control_type === 'info') {
        renderedProperty = <Info variableName={props.name} variable={props.property} group={props.group} advanced={props.advanced} hostIndex={props.hostIndex} />
    } else {
        renderedProperty = <TextInput variableName={props.name} variable={props.property} group={props.group} advanced={props.advanced} hostIndex={props.hostIndex}/>
    }

    return renderedProperty;
};

export default property;
