import React from 'react';
import { Row } from 'react-bootstrap';
import NavigationPanel from './NavigationPanel/NavigationPanel';
import 'bootstrap';
import '../../styles/VaultPassword.css';
import PropertiesEditorPanel from './PropertiesEditorPanel/PropertiesEditorPanel';
import HostsEditorPanel from './HostsEditorPanel/HostsEditorPanel';
import HostsList from './PropertiesEditorPanel/HostsList/HostsList';
import HostsEditorList from './HostsEditorPanel/HostsEditorList/HostsEditorList';
import PropertiesList from './PropertiesEditorPanel/PropertiesList/PropertiesList';
import Home from '../Home/Home';

const panel = (props) => {
    const properties = props.state !== undefined ? props.currentGroup === 'global' ? props.state.global : props.currentGroup === 'hosts' ? props.state.hosts : props.state.groups[props.currentGroup].properties : undefined;
    const title = props.state !== undefined ? props.currentGroup === 'global' ? 'Global Properties' : props.currentGroup === 'hosts' ? 'Hosts' : props.state.groups[props.currentGroup].label : null;

    const groupHosts = props.state !== undefined ? Object.entries(props.state.hosts).filter(([key, value]) => props.state.hosts[key].groups.findIndex((group) => group.name === props.currentGroup) > -1).map(([k,v]) => Object.assign(v, {name: k})): [];
    const hosts = (groupHosts.length > 0) ? 
        <HostsList
            hosts={groupHosts}
            group={props.currentGroup}
        />
    : null;

    return props.state !== undefined ? (
        <div>
            <Row style={{ marginTop: '90px' }}>
                <NavigationPanel state={props.state} currentGroup={props.currentGroup} onGroupSelect={props.onGroupSelect} />
                {props.currentGroup !== 'hosts' && (
                    <PropertiesEditorPanel>
                        <h3>{title}</h3>
                        {hosts}
                        <PropertiesList
                            group={props.currentGroup}
                            properties={properties}
                        />
                    </PropertiesEditorPanel>
                )}
                {props.currentGroup === 'hosts' && (
                    <HostsEditorPanel>
                        <h3>{title}</h3>
                        <HostsEditorList
                            hosts={props.state.hosts}
                            properties={properties}
                        />
                    </HostsEditorPanel>
                )}
            </Row>
        </div>
    ) : <Home />;
};

export default panel;
