export function obsoleteClass(isObsolete) {
    if (isObsolete) {
        return 'text-muted';
    }
    return '';
}

export function controlClass(variable, value = '', prefix = '') {
    let className = '';
    if ((variable.control_type == 'image')) {
        className = prefix + 'custom-file-input';
    } else if ((variable.isOptional !== undefined && !variable.isOptional) && (value === undefined || (typeof value === 'string' && value.trim() === ''))) {
        className = prefix + 'no-mandatory-value';
    } else if(variable.currentValue !== variable.stateValue) {
        className = prefix + 'modified-state-value';
    } else if('currentDefaultValue' in variable && 'modelDefaultValue' in variable && !('isAuto' in variable && variable.isAuto && variable.modelDefaultValue === 'auto') && variable.currentDefaultValue !== variable.modelDefaultValue) {
        className = prefix + 'modified-default-value';
    } else if('currentDefaultValue' in variable && variable.stateValue !== variable.currentDefaultValue) {
        className = prefix + 'not-default-value';
    }

    return className;
}
