import React, { Component } from 'react';
import { Form, Modal, Spinner } from "react-bootstrap";
import FileControl from '../../components/UI/FileControl/FileControl';
import VaultPassword from '../../components/UI/VaultPassword/VaultPassword';
import Button from "../../components/UI/Button/Button";
import Select from "../../components/UI/Select/Select";
import { load } from "js-yaml";
import inventorySchema from "../../model/vaultType";
import { isEncrypted } from "../../utility/util";
import WebWorker from '../../workers/inventory.worker';
import { processInventory, validateEncryption } from '../../processor/state-processor';
import merge from 'lodash/merge';

const { Title, Body, Footer } = Modal;
const { Text } = Form;
const initialState = {
    inventoryControl: {
        class: 'custom-file-input validateState',
        feedback: '',
        fileName: 'Select Inventory File'
    },
    stateControl: {
        class: 'custom-file-input validateState',
        feedback: '',
        fileName: 'Select Environment State File'
    },
    passwordControl: {
        class: 'validate',
        feedback: '',
        value: ''
    },
    inventoryVersion: {
        class: 'validate',
        feedback: '',
        value: 'model.json',
        extras: null,
        disabled: false
    },
    canProcess: false,
    inventory: undefined,
    inventoryFileName: '',
    feedback: '',
    vaultPassword: '',
    loading: false,
    state: undefined
};

class UploadInventoryDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { ...initialState };
        this.getModels();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show && this.props.show) {
            this.getModels();
        }
    }

    getModels() {
        let self = this;
        fetch('models/inventory-versions.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
        ).then(function (response) {
            console.log(response)
            return response.json();
        })
            .then(function (myJson) {
                self.inventoryVersions = myJson;
                self.setState({
                    inventoryVersion: {
                        class: 'validate',
                        feedback: '',
                        value: self.inventoryVersions[0].value,
                        extras: self.inventoryVersions[0].extras
                    }
                });
            });
    }

    getModel(model) {
        let promises = [];
        const pModel =  fetch('models/' + model.value, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
        ).then(function (response) {
                return response.json();
        });
        promises.push(pModel);
        if(model.extras){
            for (let extra of model.extras) {
                let pExtra = fetch('models/' + extra.value, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
                ).then(function (response) {
                        return response.json();
                });
                promises.push(pExtra);
            }
        }
        return Promise.all(promises);
    }

    onLoadInventory = (inventory, state, password, statusCallback) => {
        const ua = window.navigator.userAgent;
        const isIE = /MSIE|Trident/.test(ua);
        this.getModel(this.state.inventoryVersion).then(function (models) {
            const model = merge.apply(null, [{}].concat(models));
            if (typeof (Worker) !== "undefined" && !isIE) {
                let worker = new WebWorker();
                worker.postMessage({ model: model, inventory, loadedState: state, password });
                worker.onmessage = e => {
                    switch (e.data.status) {
                        case 'processing':
                            if (!!statusCallback) {
                                statusCallback(e.data);
                            }
                            break;
                        case 'success':
                            worker.terminate();
                            worker = undefined;
                            if (!!statusCallback) {
                                statusCallback(e.data);
                            }
                            break;
                        case 'error':
                            if (!!statusCallback) {
                                statusCallback(e.data);
                            }
                            break;
                        default:
                            break;
                    }
                };
            } else {
                if (validateEncryption(inventory, password)) {
                    const loadedState = processInventory(model, inventory, state, password);
                    if (!!statusCallback) {
                        statusCallback({ status: 'success', message: '', loadedState });
                    }
                } else {
                    if (!!statusCallback) {
                        statusCallback({ status: 'error', message: 'Invalid password' });
                    }
                }
            }
        });
    };

    onUpload = async (inventoryFileName, password, inventory, state) => {
        this.setState({ loading: true, invalidPassword: false, feedback: 'Loading Inventory...' });
        this.setState({ vaultPassword: password });
        this.onLoadInventory(inventory, state, password, (data) => {
            if (data.status === 'success') {
                this.setState({
                    loading: false,
                    feedback: ''
                });
                this.props.onUpload(inventoryFileName, password, data.state);
            } else if (data.status === 'error') {
                this.setState({ loading: false });
                if (data.message === 'Invalid password') {
                    this.setState({
                        invalidPassword: true,
                        feedback: ''
                    });
                } else {
                    this.setState({ feedback: !!data.message.message ? data.message.message : data.message });
                }
            } else {
                this.setState({ feedback: data.message });
            }
        });
    };

    handleInventoryVersionInput = (newValue) => {
        const inventoryVersion = this.inventoryVersions.filter(v => v.value === newValue.value)[0];
        this.setState({
            inventoryVersion: {
                class: 'validate',
                feedback: '',
                value: inventoryVersion.value,
                extras: inventoryVersion.extras
            },
            canProcess: !!this.state.inventory
        });
    };

    handlePasswordControlInput = (event) => {
        this.validatePasswordControl(event.target.value);
    };

    validatePasswordControl = (value) => {
        if (this.state.isInventoryEncrypted) {
            if (value === undefined || value === '') {
                this.setState({
                    passwordControl: {
                        class: 'validateState is-invalid',
                        feedback: 'Inventory file contains encrypted properties. Vault password is required',
                        value: ''
                    },
                    canProcess: false
                });
            } else {
                this.setState({
                    passwordControl: {
                        class: 'validate',
                        feedback: '',
                        value: value
                    },
                    canProcess: !!this.state.inventory
                });
            }
        } else {
            this.setState({
                passwordControl: {
                    class: 'validate',
                    feedback: '',
                    value: value
                },
                canProcess: !!this.state.inventory
            });
        }
    };

    handleInventoryFile = (e) => {
        const file = e.target.files[0];

        if (!!file) {
            this.fileReader = new FileReader();
            this.fileReader.onloadend = this.inventoryFileHandler;
            this.fileName = file.name;
            this.fileReader.readAsText(file);
            let index = file.name.indexOf('.yaml');
            if (index === -1) {
                index = file.name.indexOf('.yml');
            }
            this.setState({ inventoryFileName: file.name.substring(0, index) });
        }
    };

    inventoryFileHandler = (e) => {
        const content = load(this.fileReader.result, { schema: inventorySchema });
        if (!!content.all && !!content.all.vars && !!content.all.children) {
            Object.keys(content.all.vars)
                .map(name => name.trim())
                .map(name => {
                    if (isEncrypted(content.all.vars[name])) {
                        this.setState({ isInventoryEncrypted: true });
                    }
                    return null;
                });

            if (!this.state.isInventoryEncrypted) {
                Object.keys(content.all.children)
                    .map(groupName => groupName.trim())
                    .map(groupName => {
                        if (!!content.all.children[groupName].vars) {
                            Object.keys(content.all.children[groupName].vars)
                                .map(name => name.trim())
                                .map(name => {
                                    if (isEncrypted(content.all.children[groupName].vars[name])) {
                                        this.setState({ isInventoryEncrypted: true });
                                    }
                                    return null;
                                });
                        }
                        return null;
                    });
            }

            this.setState({
                inventoryControl: {
                    class: 'custom-file-input validateState is-valid',
                    feedback: '',
                    fileName: this.fileName
                },
                canProcess: true,
                inventory: content
            });
            this.validatePasswordControl(this.state.passwordControl.value);
        } else {
            this.setState({
                inventoryControl: {
                    ...this.state.inventoryControl,
                    class: 'custom-file-input validateState is-invalid',
                    feedback: 'Not an inventory file',
                    content: undefined
                },
                canProcess: false
            });
        }
    };

    handleStateFile = (e) => {
        this.setState({
            inventoryVersion: {
                class: 'validate',
                feedback: '',
                value: 'model.json',
                disabled: true
            }
        });
        const file = e.target.files[0];
        if (!!file) {
            this.fileReader = new FileReader();
            this.fileReader.onloadend = this.stateFileHandler;
            this.fileName = file.name;
            this.fileReader.readAsText(file);
        }
    };

    stateFileHandler = (e) => {
        const content = load(this.fileReader.result, {});

        if (!!content.global && !!content.groups) {
            this.setState({
                stateControl: {
                    class: 'custom-file-input validateState is-valid',
                    feedback: '',
                    fileName: this.fileName
                },
                inventoryVersion: {
                    class: 'validate',
                    feedback: '',
                    value: content.global.inventory_version ? 'model-' + content.global.inventory_version.currentValue + '.json' : 'model.json',
                    disabled: true
                },
                state: content
            });
        } else {
            this.setState({
                stateControl: {
                    ...this.state.stateControl,
                    class: 'custom-file-input validateState is-invalid',
                    feedback: 'Not a state file',
                },
                canProcess: false
            });
        }
    };

    close = () => {
        this.setState(initialState);
        this.props.onClose();
    };

    render = () => (
        <Modal show={this.props.show} backdrop={this.state.loading ? 'static' : true} onHide={this.close}>
            <Modal.Header closeButton={!this.state.loading}>
                <Title>Load Inventory</Title>
            </Modal.Header>
            <Body>
                <FileControl controlClass={this.state.inventoryControl.class} disabled={this.state.loading} fileName={this.state.inventoryControl.fileName} accepts=".yaml,.yml" onChange={this.handleInventoryFile} feedback={this.state.inventoryControl.feedback} />
                <Select label="Inventory Version" currentValue={this.state.inventoryVersion.value} disabled={this.state.inventoryVersion.disabled || this.state.loading} className={this.state.inventoryVersion.class} values={this.inventoryVersions} onChange={this.handleInventoryVersionInput} >
                    <button className="btn btn-link" data-toggle="collapse"
                        data-target="#state-control"
                        aria-expanded="true" aria-controls="state-control">
                        Advanced
                    </button>
                    <div id="state-control" className="collapse">
                        <FileControl controlClass={this.state.stateControl.class} disabled={this.state.loading} fileName={this.state.stateControl.fileName} accepts=".json" onChange={this.handleStateFile} feedback={this.state.stateControl.feedback} />
                    </div>
                </Select>

                <VaultPassword className={this.state.passwordControl.class} disabled={this.state.loading} value={this.state.passwordControl.value} onChange={this.handlePasswordControlInput} feedback={this.state.passwordControl.feedback} />
            </Body>
            <Footer>
                <Text hidden={this.state.feedback === ''}>{this.state.feedback}</Text>
                <Button disabled={!this.state.canProcess || this.state.loading} onClick={e => this.onUpload(this.state.inventoryFileName, this.state.passwordControl.value, this.state.inventory, this.state.state)} isProcessing={this.state.loading} processingText=" Uploading..." idleText="Upload" />
            </Footer>
        </Modal>
    );
}

export default UploadInventoryDialog;
