import React, { useState } from 'react';
import { controlClass } from '../../../utility/ui-util';
import { Form } from 'react-bootstrap';
import ControlLabel from "../ControlLabel/ControlLabel";
import StateContext from '../../../contexts/state-context';
import ReactMarkdown from 'react-markdown/with-html';

const ImagePicker = (props) => {
    const dataPrefix = (props.variable.accepts === ".ico") ? "data:image/x-icon;base64," : (props.variable.accepts === ".gif") ? "data:image/gif;base64," : "data:image/png;base64,";
    const [fileName, setFileName] = useState(props.variable.label);
    const fileInput = React.createRef();
    const clearButton = (props.variable.currentValue !== '' && props.variable.currentValue !== props.variable.currentDefaultValue)  ? (
        <StateContext.Consumer>
            {({ setPropertyValue }) =>
                <button className='clear'
                    title='Click to clear image'
                    onClick={e => {
                        setPropertyValue(props.group, props.advanced, props.variableName, '', props.hostIndex);
                        fileInput.current.value = '';
                        setFileName(props.variable.label);
                    }}>
                    <i className='fa fa-times' aria-hidden='true'></i>
                </button>
            }
        </StateContext.Consumer>
    ) : null;
    const displayValue = (props.variable.currentValue === '') ? props.variable.currentDefaultValue : props.variable.currentValue;
    
    return (
        <Form.Group>
            <ControlLabel isOptional={props.variable.isOptional} isObsolete={!!props.variable.isObsolete} isReadOnly={props.variable.isReadOnly} text={props.variable.label}></ControlLabel>
            <div className="custom-file mb-1">
                <StateContext.Consumer>
                    {({ setPropertyValue }) =>
                        <div className="custom-file mb-1">
                            <input type="file" id="file" className={controlClass(props.variable, (props.variable.isReadOnly && props.variable.forceDefault) ? props.variable.currentDefaultValue : props.variable.currentValue)} accept={props.variable.accepts}
                                ref={fileInput}
                                onChange={e => {
                                    const file = e.target.files[0];
                                    // Encode the file using the FileReader API
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                        // Use a regex to remove data url part
                                        const base64String = reader.result
                                            .replace('data:', '')
                                            .replace(/^.+,/, '');
                                        setPropertyValue(props.group, props.advanced, props.variableName, base64String, props.hostIndex);
                                    };
                                    setFileName(file.name);
                                    reader.readAsDataURL(file);
                                }}
                            />
                            <label className="custom-file-label" htmlFor="file">{fileName}</label>
                        </div>}
                </StateContext.Consumer>

            </div>
            <div className='image-preview-container'>
                {(!!displayValue) ?
                    <img className="image-preview" src={dataPrefix + displayValue} /> : null
                }
                {clearButton}
            </div>
            <Form.Text className="text-muted">
                <ReactMarkdown
                    source={props.variable.description}
                    escapeHtml={false} />
            </Form.Text>
        </Form.Group>
    )
};

export default ImagePicker;
